import React, { useState, useEffect } from "react";
import TrashQuestion from "./trashQuestion";
import Grid from "./grid";
import SquareWithTextEdit from "./squareWithTextEdit";
import AddOption from "./addOption";
import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";
import Explanation from "./explanation";
import Tippy from "@tippyjs/react";
import { useLocation, useNavigate } from "react-router-dom";

const SelectAllThatApplyEdit = ({ explanation, useExplanation, updateQuestions, questionsToSave, showDrag, updateQuestionsToSave, shuffle, provided, updateDeleteQuestion, index, points, question, uuid, questionType, options, answerArray, bankUuid }) => {
    const [form, setForm] = useState({
        question: question,
        points: Number(points).toFixed(2),
        uuid: uuid,
        questionType: questionType,
        options: options,
        answerArray: answerArray,
        shuffle: shuffle,
        useExplanation: useExplanation,
        explanation: explanation
    });
    const navigate = useNavigate();
    const location = useLocation();

    function updateForm(key, value) {
        setForm((prev) => (
            {
                ...prev,
                [key]: value
            }
        ))
    }

    function updateIndex(newIndex) {
        if (form.answerArray.includes(newIndex)) {
            updateForm("answerArray", form.answerArray.filter(index => index !== newIndex))
        } else {
            updateForm("answerArray", [...form.answerArray, newIndex]); // Add newIndex
        }
    }

    const submitForm = async () => {
        // construct a new body for post with all values that have changed
        let body = {};
        body.fields = {}

        // check if we have something previous
        if (form.uuid in questionsToSave) {
            body.fields = questionsToSave[form.uuid].fields
        }

        body.uuid = uuid
        body.questionType = questionType

        // Check each property for changes
        Object.keys(form).forEach(key => {
            if (form[key] !== { shuffle, points, question, uuid, options, answerArray, questionType, useExplanation, explanation }[key]) {
                if (key !== "uuid" || key !== "questionType") {
                    if (key === "points") {
                        if (form.points !== Number(points).toFixed(2)) {
                            body.fields[key] = form[key];
                        }
                    } else {
                        body.fields[key] = form[key];
                    }
                }
            }
        });

        if (Object.keys(body.fields).length === 0) {
            return;
        }

        // updateQuestionsToSave
        updateQuestionsToSave((prev) => ({
            ...prev,
            [form.uuid]: body
        }))
    };

    useEffect(() => {
        // Define a timeout to submit the form after 0.5s
        const timer = setTimeout(() => {
            submitForm();
        }, 500); // 500ms delay
    
        // Cleanup function to clear the timeout if dependencies change
        return () => {
            clearTimeout(timer);
        };
    }, [form.points, form.question, form.explanation]);

    useEffect(() => {
        submitForm();
    }, [form.answerArray, form.options, form.shuffle, form.useExplanation])

    useEffect(() => {
        updateQuestions((prev) => 
            prev.map((question) => {
                if (question.questionType === TypesOfQuestionsEnum.BANK_OF_QUESTIONS) {
                    return {
                        ...question,
                        questions: question.questions.map((nestedQuestion) => {
                            if (nestedQuestion.uuid === form.uuid) {
                                return form;
                            }
                            return nestedQuestion;
                        }),
                    };
                } else if (question.uuid === form.uuid) {
                    return form;
                }
                return question;
            })
        );
    }, [form]);

    const setNewParams = () => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("uuid", uuid);
        navigate(`?${searchParams.toString()}`);
    }
    
    return (
        <>
            <div className="true-false-question-view" id={uuid} onClick={setNewParams}>
                <div className="question-number">
                    <div className="grid-number-container">
                        {
                            showDrag

                            ?

                            <>
                                <Grid provided={provided}/>
                            </>

                            :

                            <>
                            </>

                        }
                        Q{ index }
                        <Tippy content={"Question data has ≈400KB max"} placement={"top"} theme="custom-tooltip">
                            <div className="docs-wrapper-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                                    <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                </svg>
                            </div>
                        </Tippy>
                    </div>
                    <div className="pts">
                        <>
                            <input onBlur={submitForm} className="grading-box-question" type="none" value={form.points} onChange={(e) => {
                                updateForm("points", e.target.value);
                            }}/>
                            pts
                        </>
                        <TrashQuestion uuid={uuid} updateDeleteQuestion={updateDeleteQuestion} bankUuid={bankUuid}/>
                    </div>
                </div>
                <div className="text-response-area">
                    <textarea onBlur={submitForm} className="text-response-area-input" placeholder="Enter question (render Markdown and LaTeX)" style={{ minHeight: "100px" }} value={form.question} onChange={(e) => {
                        updateForm("question", e.target.value);
                    }}/>
                </div>
                <div className="select-all-that-apply-array">
                    {
                        form && form.options.length > 0

                        ?

                        form.options.map((option) => {
                            return <SquareWithTextEdit blur={submitForm} key={option.index} correct={form.answerArray.includes(option.index)} index={option.index} text={option.option} updateIndex={updateIndex} updateQuestion={updateForm} question={form}/>
                        })

                        :

                        <>
                        </>
                    }
                    <AddOption updateQuestion={updateForm} options={form.options}/>
                    <div className="message-container" style={{ alignItems: "center" }}>
                        Answer Shuffling:
                        <input className="check-auto" onBlur={submitForm} type="checkbox" checked={form.shuffle} onChange={(e) => updateForm("shuffle", e.target.checked)} />
                    </div>
                    <Explanation form={form} updateForm={updateForm} submitForm={submitForm}/>
                </div>
            </div>
        </>
    );
}

export default SelectAllThatApplyEdit;