import React from "react";
import InstructorInfo from "./instructorInfo";
import Roster from "./roster";
import Server from "./server";
import TrashCan from "./trashCan";
import Duplicate from "./duplicate";
import BlackClipboard from "./blackClipboard";
import { useNavigate, useParams, useLocation } from "react-router-dom";

const NavInfoCourse = (props) => {
    const navigate = useNavigate();
    const { courseCode } = useParams();
    const location = useLocation();
    const currentPath = location.pathname;
    
    const handleClick = (path, event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${path}`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${path}`);
        }   
    }

    return (
        <>
            <div className="semester-nav-info">
                <div className="side-nav-info-header">
                    { props.name }
                </div>
                <div className={`open-nav ${!currentPath.endsWith("/roster") ? "current" : "hover"}`} style={{ cursor: "pointer" }} onClick={ (e) => {handleClick("", e);} }>
                    <Server /> Dashboard
                </div>
                <div className={`open-nav ${currentPath.endsWith("/roster") ? "current" : "hover"}`} style={{ cursor: "pointer" }} onClick={ (e) => {handleClick("roster", e);} }>
                    <Roster /> Roster
                </div>
                <div className="side-nav-info-header">
                    Actions
                </div>
                <div className="instructor-name underline" style={{ cursor: "pointer" }} onClick={ () => {props.updateCourse(true);} }>
                    <BlackClipboard /> Update Course
                </div>
                <div className="instructor-name underline" style={{ cursor: "pointer" }} onClick={ () => {props.updateDuplicate(true);} }>
                    <Duplicate /> Duplicate Course
                </div>
                <div className="instructor-name underline" style={{ cursor: "pointer" }} onClick={ () => {props.updateDeleteCourse(true);} }>
                    <TrashCan /> Delete Course
                </div>
                <div className="side-nav-info-header">
                    { props.instructors.length > 1 ? "Instructors" : "Instructor" }
                </div>
                <div className="meta-info-side">
                    <div className="meta-info-side-container">
                        {
                            props.instructors.map((instructor) => {
                                return <InstructorInfo key={instructor.email} instructor={instructor.name} email={instructor.email} updateInstructor={props.updateInstructor} updateRemove={props.updateRemove}/>
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default NavInfoCourse;