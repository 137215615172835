import React from "react";
import Keybind from "./keybind";
import { useLocation } from "react-router-dom";

const KeybindHints = () => {
    const location = useLocation();
    const info = [
        {
            text: "Previous Unfinished",
            command: "a"
        },
        {
            text: "Previous",
            command: "s"
        },
        {
            text: "Next",
            command: "d"
        },
        {
            text: "Next Unfinished",
            command: "f"
        }
    ]

    const infoExam = [
        {
            text: "All questions render Markdown",
            command: ""
        },
        {
            text: "Embed Block LaTeX",
            command: "$$ ... $$"
        },
        {
            text: "Embed Inline LaTeX",
            command: "$ ... $"
        }        
    ]

    return (
        <>
            <div className="keybind-hints">
                {
                    (location.pathname.endsWith("/edit") ? infoExam : info ).map((item) => {
                        return <Keybind key={item.command} text={item.text} command={item.command}/>
                    })
                }
            </div>
            <div className="arrow">

            </div>
        </>
    );
}

export default KeybindHints;