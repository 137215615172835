import { languageReadableMapLambda } from "./languageReadableMapLambda";

const findPreface = (language, index) => {

    switch (language) {
        case languageReadableMapLambda["Python 3.12"]: {
            return "my_class = MyClass()"
        }
        case languageReadableMapLambda["Java 21"]: {
            return "MyClass myClass = new MyClass();"
        }
        case languageReadableMapLambda["Node.js 22"]: {
            return "const myClass = new MyClass();"
        }
        default: {
            return ""
        }
    }
}

export default findPreface;