import React, { useEffect, useState } from "react";
import CourseInput from "./courseInput";
import SaveChanges from "./saveChanges";
import MultipleForms from "./multipleForms";
import WhiteLoading from "./whiteLoading";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import Info from "./info";
import SelectZipFile from "./selectZipFile";
import validateZipFile from "./validateZipFile";
import uploadFile from "./uploadFileToS3";
import ZipTippy from "./zipTippy";
import PdfTippy from "./pdfTippy";
import Duration from "./duration"

const EditAssessmentForm = (props) => {
    const [text, setText] = useState("Modify evaluation settings below");
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const { courseCode, assessmentCode } = useParams();
    const [metric, setMetric] = useState(props.metric);
    const [form, setForm] = useState({
        courseCode: courseCode,
        assessmentCode: assessmentCode,
        name: props.name,
        start: moment.utc(props.start).local().format('YYYY-MM-DDTHH:mm'),
        end: moment.utc(props.end).local().format('YYYY-MM-DDTHH:mm'),
        zipFile: "NA",
        pdfs: "NA",
        duration: props.duration
    });

    const resetForm = () => {
        setForm({
            courseCode: courseCode,
            assessmentCode: assessmentCode,
            name: props.name,
            start: moment.utc(props.start).local().format('YYYY-MM-DDTHH:mm'),
            end: moment.utc(props.end).local().format('YYYY-MM-DDTHH:mm'),
            zipFile: "NA",
            pdfs: "NA",
            duration: props.duration
        })
    }

    function updateMetric(metric) {
        setMetric(metric);
    }

    function updateName(name) {
        setForm(prevFrom => ({
            ...prevFrom,
            name: name
        }))
    }

    function updateStart(start) {
        setForm(prevFrom => ({
            ...prevFrom,
            start: start
        }))
    }

    function updateEnd(end) {
        setForm(prevFrom => ({
            ...prevFrom,
            end: end
        }))
    }

    function updatePdfs(pdfs) {
        setForm(prevFrom => ({
            ...prevFrom,
            pdfs: pdfs
        }))
    }

    function updateDuration(duration) {
        setForm(prevForm => ({
            ...prevForm,
            duration: duration,
        }));
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if (requestLifeCycle) {
            return;
        }

        if (form.name.length > 100) {
            setText("Evaluation name must be 99 characters or fewer")
            return;
        }

        // form validation
        for (const value in form) {
            if (form[value] === "") {
                setText("Please fill out all required fields")
                return;
            }
        }

        if (form.name.trim() === '') {
            setText("Enter a valid name")
            return;
        }

        if (form.duration === 0) {
            setText("Please enter a valid duration");
            return;
        }

        const formData = new FormData();
        formData.append('courseCode', form.courseCode);
        formData.append('assessmentCode', form.assessmentCode);
        formData.append('name', form.name);
        formData.append('start', new Date(form.start).toISOString());
        formData.append('end', new Date(form.end).toISOString());
        formData.append('duration', form.duration);
        formData.append('metric', metric);

        if (form.pdfs !== "NA") {
            formData.append('pdfs', form.pdfs.length);
        } else {
            formData.append('pdfs', 0);
        }

        const maxSizeInBytes = 50 * 1024 * 1024; // 50MB

        if (form.pdfs !== "NA") {
            for (let file of form.pdfs) {
                if (!(file.size <= maxSizeInBytes)) {
                    setText("At least one pdf exceeds 50MB");
                    return;
                }
            }
        }
        
        if (form.zipFile !== "NA") {
            const [passed, message] = await validateZipFile(form.zipFile);
            if (!passed) {
                setText("Click cancel to reset: " + message);
                return;
            }
            formData.append('zipFile', 1);
            formData.append('zipFilename', form.zipFile.name);
        } else {
            formData.append('zipFile', 0);
            formData.append('zipFilename', "NA");
        }

        const url = process.env.REACT_APP_EVALUATION_API_URL + "/update-assessment";

        const urlOptions = {
            method: "POST",
            credentials: "include",
            headers: {
                "Accept": "application/json",
            },
            body: formData
        };

        try {
            setRequestLifeCycle(true)
            const res = await fetch(url, urlOptions);
            const data = await res.json();
            if (res.status === 200) {
                if (data.urls && form.zipFile !== "NA" && data.urls.zipFileUrl) {
                    const zipStatus = await uploadFile(form.zipFile, data.urls.zipFileUrl, "application/zip");
                    
                    if (zipStatus !== 200) {
                        setText("Zip file failed to upload");
                        return;
                    }
                }

                if (data.urls && form.pdfs !== "NA" && data.urls.pdfUrls) {
                    const urls = data.urls.pdfUrls;
                    const uploadPromises = form.pdfs.map((value, index) => {
                        return uploadFile(value, urls[index], "application/pdf");
                    });
                
                    try {
                        const results = await Promise.all(uploadPromises);
                        if (results.some(result => result !== 200)) {
                            setText("Pdf failed to upload");
                            return;
                        }
                    } catch (error) {
                        setText("Pdf failed to upload");
                        return;
                    }
                }
                
                setText("Evaluation information updated");
                window.location.reload();
            } else if (res.status === 207) {
                setText("Metadata updated but pdf failed to update")
                
            } else if (res.status === 400) {
                setText(res.detail);     

            } else if (res.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            } else {
                setText("An error occurred");
            }

        } catch (error) {
            setText("An error occurred")
            console.log(error);
        } finally {
            setRequestLifeCycle(false)
        }
    }

    function updateZipFile(zipFile) {
        setForm(prevForm => ({
            ...prevForm,
            zipFile: zipFile,
        }))
    }

    useEffect(() => {
        if (form.start && form.end) {
          const start = moment(form.start);
          const end = moment(form.end);
          
          if (start.isAfter(end)) {
            setForm(prevForm => ({
              ...prevForm,
              end: ""
            }));
          }
        }
      }, [form.start]);
    
      useEffect(() => {
        if (form.start && form.end) {
          const start = moment(form.start);
          const end = moment(form.end);
          
          if (end.isBefore(start)) {
            setForm(prevForm => ({
              ...prevForm,
              start: ""
            }));
          }
        }
    }, [form.end]);

    useEffect(() => {
        setText("Modify additional settings below");
    }, [form])

    return (
        <>
            <form className="form-width">
                <div className="account-settings-content">
                    <div className="banner">
                        {
                            requestLifeCycle 

                            ?
                            
                            <>
                                <WhiteLoading />
                            </>

                            :

                            <div className="banner-info">
                                <Info />
                                { text }
                            </div>

                        }
                    </div>
                    <CourseInput star={true} top={"Evaluation Name"} value={form.name} updateValue={updateName} type={"text"} required={true} autoComplete={"one-time-code"} placeholder={"Ex. Midterm 1"}/>
                    <CourseInput star={true} top={"Available From"} type={"datetime-local"} value={form.start} updateValue={updateStart} max={""} required={true} autoComplete={"off"} placeholder={"Select date and time"}/>
                    <CourseInput star={true} top={"Available Until"} type={"datetime-local"} value={form.end} updateValue={updateEnd} required={true} autoComplete={"off"} placeholder={"Select date and time"}/>
                    <div className="points-viewing-container">
                        <div style={{ width: "45%", position: "relative" }}>
                            <MultipleForms value={form.pdfs} updateValue={updatePdfs} top={"Add PDF Form(s)"} required={true}/>
                            <PdfTippy left={"115px"}/>
                        </div>
                        <div style={{ width: "45%", position: "relative" }}>
                            <SelectZipFile star={false} value={form.zipFile} updateValue={updateZipFile} top={"New Zip File"} required={true}/>
                            <ZipTippy left={"85px"}/>
                        </div>  
                    </div>
                    <div className="points-viewing-container" style={{ position: "relative", width: "100%" }}>
                        <div className="points-viewing-container" style={{ position: "relative", width: "45%" }}>
                            <Duration metric={metric} duration={form.duration} updateDuration={updateDuration} updateMetric={updateMetric}/>    
                        </div>                            
                    </div>
                    <SaveChanges left="Cancel" right={{ small : "Update Evaluation", large : "Update Evaluation" }} resetForm={resetForm} submitForm={submitForm}/> 
                </div>
            </form>
        </>
    );
}

export default EditAssessmentForm;