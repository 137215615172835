import React from "react";
import Server from "./server";
import Settings from "./settings";
import Graph from "./graph";
import Document from "./document";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Clipboard2 from "./clipboard2";

const NavInfoSubmissionsCollapsed = (props) => {
    const location = useLocation();
    const currentPath = location.pathname;
    const { courseCode, assessmentCode } = useParams();
    const navigate = useNavigate();

    const handleDocumentClick = async (event) => {
        if (!currentPath.includes("@")) {
            try {
                const url = process.env.REACT_APP_SUBMISSION_API_URL + `/get-next-submission/${courseCode}/${assessmentCode}/na`;
                const urlOptions = {
                    method: "GET",
                    credentials: "include"
                }
                
                const response = await fetch(url, urlOptions);
                const data = await response.json();
                if (response.status === 200) {
                    if (event.metaKey || event.ctrlKey) {
                        // Open in a new tab
                        window.open(`/${courseCode}/${assessmentCode}/${data.email}`, '_blank');
                    } else {
                        // Navigate in the same tab
                        navigate(`/${courseCode}/${assessmentCode}/${data.email}`);
                    }
                } else if (response.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL
                }
    
    
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleEditClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/edit`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/edit`)
        }
    }

    return (
        <>
            <div className="collapsed-container" style={{ marginTop: "175px" }}>
                <div className={`collapsed ${!currentPath.endsWith("/statistics") && !currentPath.endsWith("/settings") && !currentPath.includes("@") && !currentPath.endsWith("/edit") ? "current" : "hover"}`} style={{ cursor: "pointer" }} onClick={ props.handleDashboardClick }>
                    <Server />
                </div>
                <div className={`collapsed ${currentPath.endsWith("/edit") ? "current" : "hover"}`} style={{ cursor: "pointer" }} onClick={  handleEditClick  }>
                    <Clipboard2 />
                </div>
                <div className={`collapsed ${currentPath.endsWith("/settings") ? "current" : "hover"}`} style={{ cursor: "pointer" }} onClick={ props.handleDetailsClick }>
                    <Settings />
                </div>
                <div className={`collapsed ${currentPath.includes("@") ? "current" : "hover"}`} style={{ cursor: "pointer" }} onClick={ handleDocumentClick }>
                    <Document />
                </div>           
                <div className={`collapsed ${currentPath.endsWith("/statistics") ? "current" : "hover"}`} style={{ cursor: "pointer" }} onClick={ props.handleStatisticsClick }>
                    <Graph />
                </div>
            </div>
        </>
    );
}

export default NavInfoSubmissionsCollapsed;