async function fetchWithPreSigned(url) {
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {}
        });      
        
        const blob = await response.blob()
        return [response.status, blob]
    } catch (error) {        
        console.error("Error uploading file:", error);
        return [400, null]
    }
}

export default fetchWithPreSigned ;