import React from "react";
import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";

const TypeQuestionSelectBank = ({ updateQuestion, questionType }) => {
    const excludedTypes = ["CONTEXT_BLOCK", "BANK_OF_QUESTIONS"];

    // Filter the types by excluding the specified ones
    const filteredTypes = Object.entries(TypesOfQuestionsEnum).filter(([key, value]) => {
        return !excludedTypes.includes(key); // Exclude types that are in the excludedTypes array
    });

    return (
        <>
            <select className="metric-select question" value={questionType} onChange={(e) => {
                updateQuestion("questionType", e.target.value);
            }} style={{ width: "100%" }}>
                {filteredTypes.map(([key, value]) => (
                    <option key={key} value={value}>
                        {value
                            .replace(/_/g, " ") // Replace underscores with spaces
                            .toLowerCase()      // Convert to lowercase
                            .split(" ")         // Split into words
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
                            .join(" ")          // Join back into a string
                        }
                    </option>
                ))}
            </select>
        </>
    );
};

export default TypeQuestionSelectBank;
