import React from "react";
import Navigation from "./navigation";
import NavInfoAutograder from "./navInfoAutograder";
import ReactMarkdown from "react-markdown";
import markdownDocsLambda from "./markdownDocsLambda";

const LambdaDocs = () => {
    return (
        <>
            <Navigation info={<NavInfoAutograder />}/>
            <div className="autograder-container">
                <div className="autograder-documentation">
                    <ReactMarkdown>{markdownDocsLambda}</ReactMarkdown>
                </div>            
            </div>
        </>
    );
}

export default LambdaDocs;