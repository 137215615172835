import React from "react";
import CustomExamWrapperView from "./customExamWrapperView";
import { MathJaxContext } from "better-react-mathjax";
import mathJaxConfig from "./mathJaxConfig";
import OnlyIInfo from "./onlyIInfo";
import Loading from "./loading";

const GradingPageCustomExamWrapper = ({ status, endedExam, error, testsConfigDict, id, updateId, ignoredPaths, ignoredPrefixes, requestLifeCycleTwo, requestLifeCycle, updatePdfUrls, pdfUrls, isDarkTheme, updateIsDarkTheme, contents, gradedQuestions, updateCurrentViewer }) => {
    
    return (
        <>
            {
                requestLifeCycle ?

                <>
                    <div className="loading-zip-container">
                        <Loading />
                    </div>
                </>

                :

                <>
                    <div className="custom-programming-exam-wrapper" style={{ position: "relative" }}>
                        <MathJaxContext config={mathJaxConfig}>
                            <CustomExamWrapperView status={status} endedExam={endedExam} error={error} testsConfigDict={testsConfigDict} id={id} updateId={updateId} ignoredPaths={ignoredPaths} ignoredPrefixes={ignoredPrefixes} requestLifeCycleTwo={requestLifeCycleTwo} updatePdfUrls={updatePdfUrls} pdfUrls={pdfUrls} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} useSyntaxHighlighting={true} updateCurrentViewer={updateCurrentViewer} contents={contents} questions={gradedQuestions} grading={true}/>
                        </MathJaxContext>
                        <OnlyIInfo />
                    </div>
                </>
            }            
        </>
    );
}

export default GradingPageCustomExamWrapper;