import React from "react";
import useWindowWidth from "./useWindowWidth";
import Remove from "./remove";

const Student = ({ name, email, extraTime, updateStudent, updateRemoveStudent, updateAddTime, ...restProps }) => {
    const width = useWindowWidth(685);

    const handleRemoveClick = () => {
        updateStudent(name, email);
        updateRemoveStudent(true);
    };

    const handleTimeClick = () => {
        updateStudent(name, email);
        updateAddTime(true);
    };

    return (
        <>
            <div className="exam-container">
                <div className="course-metadata-2">
                    <div className="roster-meta">
                        {width ? (
                            <>
                                <div className="color one roster-student small" style={{ marginRight: "20px" }}>
                                    <div className="name-student">
                                        <div className="name-student-2" onClick={handleRemoveClick}>
                                            <Remove /> {name}
                                        </div>
                                    </div>
                                </div>
                                <div className="color roster-student small" style={{ textAlign: "right" }}>
                                    {email}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="color one roster-student" style={{ marginRight: "20px" }}>
                                    <div className="name-student">
                                        <div className="name-student-2" onClick={handleRemoveClick}>
                                            <Remove /> {name}
                                        </div>
                                    </div>
                                </div>
                                <div className="color two roster-student">
                                    {email}
                                </div>
                                <div className="color three roster-student">
                                    <div className="extra-time">
                                        <div className="extra-time-2" onClick={handleTimeClick}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                <path fill="rgb(60,60,60)" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Student;
