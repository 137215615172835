async function uploadFile(file, url, fileType) {
    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': fileType,
            },
            body: file,
        });
        return response.status
    } catch (error) {
        console.error("Error uploading file:", error);
        return 400;
    }
}

export default uploadFile;