import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";

const findStillProcessing = (questionsArray, status) => {
    if (status === 'RUNNING') {
        return true;
    }

    if (!questionsArray) {
        return false;
    }
    
    for (let i = 0; i < questionsArray.length; i++) {
        let currentQuestion = questionsArray[i];

        if (currentQuestion.questionType === TypesOfQuestionsEnum.LAMBDA && currentQuestion.status === "RUNNING") {
            return true;
        }
    }

    return false;
}

export default findStillProcessing