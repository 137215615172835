import React, { useEffect, useState } from "react";
import TrashQuestion from "./trashQuestion";
import Grid from "./grid";
import BankOfQuestionsOption from "./bankOfQuestionsOption";
import AddAQuestionToBankForm from "./addAQuestionToBankForm";
import Tippy from "@tippyjs/react";

const BankOfQuestions = ({ updateQuestions, typeOfQuestionToReturn, provided, updateDeleteQuestion, index, points, questions, uuid, updateFetchData }) => {
    const [current, setCurrent] = useState(questions?.length > 0 ? questions.length - 1 : 0);
    const [addAQuestion, setAddAQuestion] = useState(false);

    function updateCurrent(current) {
        setCurrent(current);
    }

    function updateAddAQuestion(bool) {
        setAddAQuestion(bool);
    }

    useEffect(() => {
        setCurrent(questions?.length > 0 ? questions.length - 1 : 0)
    }, [questions.length])
   
    return (
        <>
            <div className="true-false-question-view" id={uuid}>
                <div className="question-number">
                    <div className="grid-number-container">
                        <Grid provided={provided}/>
                        B{ index }
                        <Tippy content={"≈25000 nested questions max"} placement={"top"} theme="custom-tooltip">
                            <div className="docs-wrapper-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                                    <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                </svg>
                            </div>
                        </Tippy>
                    </div>
                    <div className="pts">
                        <>                                           
                           { Number(points).toFixed(2) } pts
                        </>
                        <TrashQuestion uuid={uuid} updateDeleteQuestion={updateDeleteQuestion} bankUuid={uuid}/>
                    </div>
                </div>
                <div className="text-response-area">
                    <div className="bank-questions-select">
                        <div style={{ cursor: "pointer" }} onClick={() => updateAddAQuestion(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path stroke={"var(--almost-black)"} strokeWidth={"1"} d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
                            </svg>
                        </div>
                        {
                            questions && questions.length > 0

                            ?

                            <>
                                {
                                    questions.map((question, index) => {
                                        return <BankOfQuestionsOption key={question.uuid} current={current === index} index={index + 1} updateCurrent={updateCurrent}/>
                                    })
                                }
                            </>

                            :

                            <>
                            </>

                        }
                    </div>
                </div>
                <div className="text-response-area">
                    {
                        questions && questions.length > 0
                        ?
                        <>
                            {questions.map((question, index) => (
                                <div 
                                    key={index} 
                                    style={{ display: index === current ? 'block' : 'none' }}
                                >
                                    {typeOfQuestionToReturn(question, index + 1, provided, false, uuid, false)}
                                </div>
                            ))}
                        </>
                        :
                        <></>
                    }
                </div>
                <div className="text-response-area">
                    <span className="required" style={{ fontSize: "var(--font-size-one)" }}>
                        *
                    </span>
                    <span className="complete-on-scan">
                        {" One question randomly assigned upon evaluation start."}
                    </span>
                </div>
            </div>
            { addAQuestion ? <AddAQuestionToBankForm updateQuestions={updateQuestions} bankUuid={uuid} updateAddAQuestion={updateAddAQuestion}/> : <></> }
        </>
    );
}

export default BankOfQuestions;