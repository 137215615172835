import React, { useState } from "react";
import Editor from "@monaco-editor/react";
import DarkThemeQuestion from "./darkThemeQuestion";
import GradingBoxQuestion from "./gradingBoxQuestion";
import MarkdownWithLaTeX from "./markDownWithLatex";
import Loading from "./loading";
import ASingleTestGrading from "./aSingleTestGrading";
import FullScreenToggler from "./fullScreenToggler";

const CodeResponseView = ({ status, error, tests, testsConfigDict, id, updateId, ignoredPaths, ignoredPrefixes, requestLifeCycleTwo, updateContents, useSyntaxHighlighting, location, contents, useAutocompletion, questionNumber, question, language, isDarkTheme, updateIsDarkTheme, points, grading, uuid, pointsEarned, studentAnswer }) => {
    const [answer, setAnswer] = useState(studentAnswer);
    const [fullscreen, setFullscreen] = useState(false);

    const handleAnswer = (value) => {
        setAnswer(value);
    }

    const handleChange = (value) => {
        if (!grading) {
            updateContents((prev) => ({
                ...prev,
                [location]: {
                    content: value,
                    isDir: false
                }
            }));
        }
    }

    const isValidAndNotIgnoreLocation = contents && location in contents && !(ignoredPaths && location in ignoredPaths) && !(ignoredPrefixes && Array.from(ignoredPrefixes).some(prefix => location.startsWith(prefix)));

    const determineIfWeHaveATestToShow = () => {
        const dictLength = testsConfigDict && Object.keys(testsConfigDict).length > 0;
        
        if (tests && tests.length > 0 && dictLength) {
            for (const key of tests) {
                if (key in testsConfigDict) {
                    return true;
                }
            }
        } else if (error && typeof error === "string") {
            return true;
        }
        
        return false;
    }

    function updateFullscreen(fullscreen) {
        setFullscreen(fullscreen);
    }

    return (
        <>
            <div className="true-false-question-view" id={uuid + "-preview"} >
                <div className="question-number">
                    <div className="grid-number-container">
                        Q{ questionNumber }
                        {
                            grading && <input type={"checkbox"} className="check-auto" checked={uuid === id} onChange={() => {updateId(uuid === id ? "" : uuid)}} style={{ cursor: "pointer" }}/>
                        }
                    </div>
                    <div className="pts">
                        {
                            grading

                            ?

                            <>
                                <GradingBoxQuestion uuid={uuid} pointsEarned={pointsEarned}/>
                            </>

                            :

                            <>
                            </>
                        }
                        { (grading ? "/ " : "") + Number(points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={question} isDarkTheme={isDarkTheme}/>
                </div>
                <div className="code-response-area-wrapper">
                    {
                        requestLifeCycleTwo ?
                        
                        <>
                            <div className="loading-zip-container">
                                <Loading />
                            </div>
                        </>

                        :

                        <>
                            <div className={`code-response-area ${fullscreen ? "fullscreen-editor-side-bar" : ""}`} style={{ backgroundColor: isDarkTheme ? "var(--vs-background)" : "white", color: isDarkTheme ? "white" : "var(--almost-black)" }}>
                                <div className="code-editor-header">
                                    <FullScreenToggler updateFullscreen={updateFullscreen} fullscreen={fullscreen}/>
                                    <DarkThemeQuestion isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme}/>
                                </div>                        
                                <Editor
                                    language={grading ? language : (useSyntaxHighlighting ? language : "plaintext")}
                                    value={isValidAndNotIgnoreLocation ? contents[location].content : answer}
                                    theme={ isDarkTheme ? "vs-dark" : "vs"}
                                    onChange={isValidAndNotIgnoreLocation ? (value) => handleChange(value) : handleAnswer}
                                    options={{
                                        fontFamily: "Consolas, 'Courier New', monospace", // Default font family
                                        fontSize: 14, // Default font size (14px)
                                        tabSize: 8,         // Set tab length to 4 spaces
                                        fontWeight: "500", // Default font weight
                                        suggestOnTriggerCharacters: useAutocompletion,
                                        quickSuggestions: useAutocompletion,  
                                        readOnly: grading,
                                        contextmenu: false                            
                                    }}
                                />
                                <div className="code-editor-header" />
                            </div>
                        </>

                    }                    
                </div>
                {
                    grading && determineIfWeHaveATestToShow() && (
                        // if there is an error or a test to show, show either based on error
                        <div className="true-false-top lambda-results-container">
                            {                                
                                
                                error ? 
                                
                                <div style={{ color: status === "RUNNING" ? "var(--gray-six)" : "" }}>
                                    {error}
                                </div>

                                :

                                tests.map((id) => {
                                    
                                    if (id in testsConfigDict) {
                                        return <ASingleTestGrading greyedOut={status === "RUNNING"} key={id} {...testsConfigDict[id]}/>
                                    }
                                })
                            }
                        </div>
                    )
                }
            </div>
        </>
    );
}

export default CodeResponseView;