import React, { useRef, useEffect, useState } from "react";
import { Tree } from "react-arborist";
import Directory from "./directory";
import DocumentTreeIcon from "./documentTreeIcon";
import useWindowWidth from "./useWindowWidth";

const FileTreeVisualizer = ({ contents, updateCurrentFilename, updateCurrentFileData }) => {
    const width = useWindowWidth(1000);
    const containerRef = useRef(null);
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

    function Node({ node, style }) {
        const newLeftPadding = style.paddingLeft * .4;
        return (
          <div className={`node ${ node.isLeaf ? "node-hover" : ""}`} style={{paddingLeft: newLeftPadding}} onClick={() => {
            if (node.isLeaf) {
                updateCurrentFilename(node.data.name);
                updateCurrentFileData(node.data.text);
            }
          }}>
            {node.isLeaf ? <DocumentTreeIcon /> : <Directory />}
            {node.data.name}
          </div>
        );
      }

      // Update container size on window resize
    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                setContainerSize({
                    width: containerRef.current.offsetWidth,
                    height: containerRef.current.offsetHeight,
                });
            }
        };

        // Initial size update
        handleResize();

        // Add event listener for resizing
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    
    return (
        <>
            <div ref={containerRef} className="file-visualizer-open" style={{ width: width ? "100%" : "", height: width ? "fit-content" : "" }}>
                {
                    contents && contents.length > 0

                    ?

                    <Tree initialData={contents} width={containerSize.width} height={containerSize.height}>
                        {Node}
                    </Tree>

                    :

                    <>
                    </>
                }
            </div>
        </>
    );
};


export default FileTreeVisualizer;
