import React from "react";
import GradedPercent from "./gradedPercent";

const GradedPercentWrapper = ({ countsInformation }) => {
    return (
        <>
            {
                countsInformation.submissionsLength > 0 && countsInformation.gradedLength !== null

                ?

                <>
                    <div className="side-nav-info-header">
                        Submitted
                    </div>
                    <div className="text-side-nav display-2" style={{ width: "100%" }}>
                        <GradedPercent submissionsLength={countsInformation.submissionsLength} gradedLength={countsInformation.gradedLength} moreInfo={true}/>
                    </div>
                </>

                :

                <>
                </>
            }
        </> 
    );
}

export default GradedPercentWrapper;