import Tippy from "@tippyjs/react";
import React from "react";

const PdfTippy = ({ left, top, content, placement, error }) => {
    return (
        <>
            <Tippy content={ content ? content : "PDF randomly assigned upon start"} placement={placement ? placement : "bottom"} theme="custom-tooltip">
                <div className="pdf-docs-wrapper-icon docs-wrapper-icon" style={{ left: left, top: top ? top : "", color: error ? "red" : "", border: error ? "1px solid red" : "" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                        <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                    </svg>
                </div>
            </Tippy>
        </>
    );
}

export default PdfTippy;