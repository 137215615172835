import React from "react";
import useWindowWidth from "./useWindowWidth";
import findPlaceholderTest from "./findPlaceholderTest";
import Tippy from "@tippyjs/react";
import findPreface from "./findPreface";

const ASingleTestEdit = ({ testName, expression, passPoints, failPoints, testsMap, updateForm, uuid, currentTest, langauge, index, preface }) => {
    const width = useWindowWidth(800)
    const widthTwo = useWindowWidth(410);

    const updateTests = (newValue, type) => {
        const newTests = {
            ...testsMap,
            [uuid]: {
                ...currentTest,
                [type]: newValue
            } 
        }

        updateForm("testsMap", newTests)
    }

    const deleteTest = () => {
        const newTests = Object.keys(testsMap).reduce((acc, id) => {
            if (id !== uuid) {
              acc[id] = testsMap[id];
            }
            return acc;
          }, {});
          
          updateForm("testsMap", newTests);          
    }

    return (
        <>
            <div className="single-test-wrapper">
                <input placeholder={"Your " + langauge.toString() + " test name"} className="test-text-input" value={testName} onChange={(e) => {updateTests(e.target.value, "testName")}}/>
                <textarea placeholder={findPreface(langauge, index + 1)} className="test-text-area" value={preface} onChange={(e) => updateTests(e.target.value, "preface")} />
                <div className="a-single-test-edit">
                    <div className="sub-sec-lambda-container">
                        <div className="trash-question-test" onClick={deleteTest}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
                            </svg>
                        </div>
                        IF
                        <div>   
                            (
                            <input placeholder={findPlaceholderTest(langauge, index + 1)} type={"text"} className="lambda-input" value={expression} onChange={(e) => updateTests(e.target.value, "expression")} style={{ maxWidth: "390px", width: width ? (widthTwo ? "110px" : "") : "500px" }}/>
                            )
                        </div>                
                    </div>
                    <div className="sub-sec-lambda-container">
                        {
                            widthTwo ? 

                            <div className="wrap-lambdas-tests">
                                <div className="sub-sec-lambda-container">
                                    THEN
                                    <input placeholder={"1.00"} type={"number"} className="lambda-input" value={passPoints} onChange={(e) => updateTests(e.target.value, "passPoints")} style={{ maxWidth: "62.5px", width: width ? "" : "500px"  }}/>
                                </div>
                                <div className="sub-sec-lambda-container">
                                    ELSE
                                    <input placeholder={"0.00"} type={"number"} className="lambda-input" value={failPoints} onChange={(e) => updateTests(e.target.value, "failPoints")} style={{ maxWidth: "62.5px", width: width ? "" : "500px"  }}/>
                                    <Tippy content={"Add points to total score by condition outcome"} placement={"top"} theme="custom-tooltip">
                                        <div className="docs-wrapper-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                                                <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                            </svg>
                                        </div>
                                    </Tippy>
                                </div>
                            </div>

                            :
                            
                            <>
                                THEN
                                <input placeholder={"1.00"} type={"number"} className="lambda-input" value={passPoints} onChange={(e) => updateTests(e.target.value, "passPoints")} style={{ maxWidth: "62.5px", width: width ? "" : "500px"  }}/>
                                ELSE
                                <input placeholder={"0.00"} type={"number"} className="lambda-input" value={failPoints} onChange={(e) => updateTests(e.target.value, "failPoints")} style={{ maxWidth: "62.5px", width: width ? "" : "500px"  }}/>
                                <Tippy content={"Add points to total score by condition outcome"} placement={"top"} theme="custom-tooltip">
                                    <div className="docs-wrapper-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                                            <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                        </svg>
                                    </div>
                                </Tippy>
                            </>                                                        
                        }                    
                    </div>
                </div>
            </div>
        </>
    );
}

export default ASingleTestEdit