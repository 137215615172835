import React, { useEffect, useState } from "react";
import CourseInput from "./courseInput";
import SaveChanges from "./saveChanges";
import { useParams } from "react-router-dom";
import WhiteLoading from "./whiteLoading";
import Info from "./info";
import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";
import TypeQuestionSelectBank from "./typeQuestionSelectBank";
import createQuestion from "./createQuestion";

const AddAQuestionToBankForm = ({ bankUuid, updateAddAQuestion, updateQuestions }) => {
    const [text, setText] = useState("Addition will not affect existing submissions");
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const { courseCode, assessmentCode } = useParams();
    const [form, setForm] = useState({
        questionType: TypesOfQuestionsEnum.MULTIPLE_CHOICE,
        points: "1",
        courseCode: courseCode,
        assessmentCode: assessmentCode,
        question: "",
        bankUuid: bankUuid
    })

    function updatePoints(points) {
        if (requestLifeCycle) {
            return;
        }
        
        setForm(prevForm => ({
            ...prevForm,
            points: points,
        }));
        console.log(form);
    }

    function updateQuestionType(questionType, value) {
        if (requestLifeCycle) {
            return;
        }

        if (value === TypesOfQuestionsEnum.CONTEXT_BLOCK || value === TypesOfQuestionsEnum.BANK_OF_QUESTIONS ) {
            setForm(prevForm => ({
                ...prevForm,
                points: "0",
            }));
        }
        setForm(prevForm => ({
            ...prevForm,
            [questionType]: value,
        }));
    }

    const resetForm = () => {
        updateAddAQuestion(false);
    }

    const addQuestion = (uuid) => {
        const questionChild = createQuestion(form, uuid);
    
        if (questionChild === null) {
            setText("Question addition failed")
            return;
        }

        updateQuestions((prevQuestions) =>
            prevQuestions.map((question) =>
                question.uuid === bankUuid
                ? {
                    ...question, // Create a new object
                    questions: [...question.questions, questionChild], // Copy and update the items array
                  }
                : question // Leave other objects unchanged
            )
          );

        updateAddAQuestion(false);
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if (requestLifeCycle) {
            return;
        }

        // form validation
        for (const value in form) {
            if (form[value] === "" && value === "points") {
                setText("Please enter a valid point amount")
                return;
            }
        }

        const url = process.env.REACT_APP_EVALUATION_API_URL + "/add-question-to-bank"

        const urlOptions = {    
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(form)
        }

        try {
            setRequestLifeCycle(true)
            const res = await fetch(url, urlOptions);
            const data = await res.json();
            
            if (res.status === 200 && data.uuid) {
                setText("Question added")
                updateAddAQuestion(false);
                addQuestion(data.uuid);
            }
            else if (res.status === 401) {
                setText("Authentication error")
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            } else {
                setText("An error occurred")
            }

        } catch (error) {
            setText("An error occurred")
            console.log(error);
        } finally {
            setRequestLifeCycle(false)
        }
        
    }

    useEffect(() => {
        setText("Addition will not affect existing submissions");
    }, [form])

    return (
        <>
            <div className="black-back" onClick={ () => {updateAddAQuestion(false)} } style={{ opacity: 0 }}>

            </div>
            <div className="add-a-course-form-container add-a-question">
                <div className="add-a-course-form-content-container">
                    <form className="form-width">
                        <div className="add-course-title">
                            Add A Question To Bank
                        </div>
                        <div className="account-settings-content">
                            <div className="required-container">
                                All Fields Required
                            </div>   
                            <div className="banner">
                                {
                                    requestLifeCycle 

                                    ?
                                    
                                    <>
                                        <WhiteLoading />
                                    </>

                                    :

                                    <div className="banner-info">
                                        <Info />
                                        { text }
                                    </div>

                                }
                            </div>
                            <div className="points-viewing-container">
                                <div style={{ width: "45%" }}>
                                    <div className="input-field">
                                        <div className="top-input">
                                        Select Question Type
                                        </div>
                                        <TypeQuestionSelectBank updateQuestion={updateQuestionType} questionType={form.questionType}/>
                                        
                                    </div>                                        
                                </div>
                                <div style={{ width: "45%" }}>
                                    <CourseInput top={"Total Points"} value={form.points} updateValue={updatePoints} type={"number"} required={true} autoComplete={"off"} min={0}/>
                                </div>
                            </div>
                            <SaveChanges left="Cancel" right={{ small : "Add A Question", large : "Add A Question" }} resetForm={resetForm} submitForm={submitForm}/> 
                        </div>
                    </form> 
                </div>
            </div>
        </>
    );
}

export default AddAQuestionToBankForm;