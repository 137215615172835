function retIgnoredPrefixes(ignoreFiles) {
    // Initialize a set to store prefixes
    const ignorePrefixes = new Set();

    // Extract prefixes for directories
    for (const key in ignoreFiles) {
        if (key.endsWith("/*")) {
            const prefix = key.replace(/\/\*$/, "/"); // Remove "/*" and ensure trailing slash
            ignorePrefixes.add(prefix);
        }
    }

    return ignorePrefixes;
}

export default retIgnoredPrefixes