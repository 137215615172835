import React from "react";

const AddCourseDescription = (props) => {

    const handleChange = (e) => {
        props.updateValue(e.target.value);
    }

    return (
        <>
            <div className="input-field" style={{ height: "auto" }}>
            <div className="top-input">
                    { props.top }
                </div>
                <textarea className="bottom-input-2" type={ "text" } value={ props.value } required={ props.required } onChange={ handleChange } autoComplete={ "off" } placeholder={ "" } />
            </div>
        </>
    );
}

export default AddCourseDescription;