import React, { useState } from "react";
import SaveChanges from "./saveChanges";
import { useParams } from "react-router-dom";
import WhiteLoading from "./whiteLoading";
import Info from "./info";

const DeleteZipForm = (props) => {
    const [text, setText] = useState("Are you sure you want to delete the current zip file?");
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const { courseCode, assessmentCode } = useParams();

    const resetForm = () => {
        props.updateDeleteZip(false);
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if (requestLifeCycle) {
            return;
        }

        const url = process.env.REACT_APP_EVALUATION_API_URL + `/delete-zip/${courseCode}/${assessmentCode}`

        const urlOptions = {
            method: "POST",
            credentials: "include"
        }

        try {
            setRequestLifeCycle(true)
            const res = await fetch(url, urlOptions);            
            if (res.status === 200) {
                setText("Zip file deleted")            
                props.updateDeleteZip(false);
                window.location.reload()
            } else if (res.status === 404) {
                setText("Evaluation not found")
            } else if (res.status === 401) {
                setText("Authentication error")
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            } else {
                setText("An error occurred")
            }

        } catch (error) {
            setText("An error occurred")
            console.log(error);
        } finally {
            setRequestLifeCycle(false)
        }
    }

    return (
        <>
            <div className="black-back" onClick={ () => {props.updateDeleteZip(false)} }>

            </div>
            <div className="add-a-course-form-container">
                <div className="add-a-course-form-content-container">
                    <form className="form-width">
                        <div className="add-course-title">
                            Delete Current Zip
                        </div>
                        <div className="account-settings-content">
                            <div className="required-container">
                                All Fields Required
                            </div>   
                            <div className="banner">
                                {
                                    requestLifeCycle 

                                    ?
                                    
                                    <>
                                        <WhiteLoading />
                                    </>

                                    :

                                    <div className="banner-info">
                                        <Info />
                                        { text }
                                    </div>

                                }
                            </div>
                            <SaveChanges left="Cancel" right={{ small : "Delete", large : "Delete" }} resetForm={resetForm} submitForm={submitForm}/> 
                        </div>
                    </form> 
                </div>
            </div>
        </>
    );
}

export default DeleteZipForm;