import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";

const findUuidsOfScanAndGrade = (gradedQuestions) => {
    const questionsDict = {}
    gradedQuestions.map((question) => {
        if (question.questionType === TypesOfQuestionsEnum.SCAN_AND_GRADE) {
            questionsDict[question.uuid] = null;
        }
    })

    return questionsDict;
}

export default findUuidsOfScanAndGrade;