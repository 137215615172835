import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import HandleStatus from "./handleStatus";
import convertDate from "./convertDate";
import GradedPercent from "./gradedPercent";

const Assessment = (props) => {
    const navigate = useNavigate();
    const { courseCode } = useParams();

    const handleNameClick = async (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${props.assessmentCode}/${props.submissionsLength > 0 ? "" : "edit"}`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${props.assessmentCode}/${props.submissionsLength > 0 ? "" : "edit"}`);
        }
    }

    const handleSubmitted = () => {
        if (props.status.includes("Submitted")) {
            return props.submissionsLength
        } else {
            return props.status
        }
    }

    return (
        <>
            <div className="exam-container">
                <div className="course-metadata-2"> 
                    <div className={ props.widthTwo ? "arrow-container-1 color fifty" : "arrow-container-1 color" }>
                        <div className="assessment-name-container">
                            { props.widthTwo ? HandleStatus(props.status) : ""} 
                            <div className="assessname" onClick={handleNameClick}> { props.name } </div>
                        </div>
                    </div>
                    {
                        props.widthTwo

                        ?
                            <></>
                        :

                            <div className={ props.width ? "arrow-container-2-2 color" : "arrow-container-2 color" }>
                                { HandleStatus(props.status) } { handleSubmitted() }
                            </div>

                    }
                    
                    {
                        props.width

                        ?
                            <></>
                        :

                            <div className="arrow-container-3 color">
                                <GradedPercent submissionsLength={props.submissionsLength} gradedLength={props.gradedLength}/>
                            </div> 
                    }
                    <div className={ props.width ? props.widthTwo ? "arrow-container-4-2 color special fifty" : "arrow-container-4-2 color special" : "arrow-container-4 color special" }>
                        <div>
                            { convertDate(props.start, true) }
                        </div> 
                        <div>
                            { convertDate(props.end, true) }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Assessment;