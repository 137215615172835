import React, { useEffect, useState } from "react";
import TrueFalseQuestionView from "./trueFalseQuestionView";
import SelectAllThatApplyView from "./selectAllThatApplyView";
import TextResponseView from "./textResponseView";
import CodeResponseView from "./codeResponseView";
import MultipleChoiceView from "./multipleChoiceView";
import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";
import ContextBlockView from "./contextBlockView";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CompleteOnPaperView from "./completeOnPaperView";
import NotFoundSingleQ from "./notFoundSingleQ";
import LambdaResponseView from "./lambdaResponseView";

const CustomExamWrapperView = ({ status, endedExam, error, testsConfigDict, id, updateId, ignoredPaths, ignoredPrefixes, requestLifeCycleTwo, updatePdfUrls, pdfUrls, updateContents, isDarkTheme, updateIsDarkTheme, useSyntaxHighlighting, contents, questions, grading, updateIsEditing, updateAddAQuestion, updateCurrentViewer }) => {
    const [arrayOfIndexes, setArrayOfIndexes] = useState([])
    const navigate = useNavigate();
    const { courseCode, assessmentCode } = useParams();
    const locationURL = useLocation();

    function updateArrayOfIndexes(newElement) {
        setArrayOfIndexes((prevArray) => ([
            ...prevArray,
            newElement
        ]))
    }

    const typeOfQuestionToReturn = (question, index) => {
        switch (question.questionType) {
            case TypesOfQuestionsEnum.TRUE_FALSE: {
                return <TrueFalseQuestionView id={id} updateId={updateId} isDarkTheme={isDarkTheme} useCustomScore={question.useCustomScore} studentAnswer={question.studentAnswer} bubbledAnswer={question.bubbled} pointsEarned={question.pointsEarned} grading={grading} key={question.uuid} uuid={question.uuid} questionNumber={index} points={question.points} question={question.question}/>
            }
            case TypesOfQuestionsEnum.SELECT_ALL: {
                return <SelectAllThatApplyView id={id} updateId={updateId} isDarkTheme={isDarkTheme} useCustomScore={question.useCustomScore} studentAnswer={question.studentAnswer} answerArrayAnswer={question.answerArray} pointsEarned={question.pointsEarned} grading={grading} key={question.uuid} uuid={question.uuid} questionNumber={index} points={question.points} question={question.question} options={question.options}/>
            }
            case TypesOfQuestionsEnum.SHORT_RESPONSE: {
                return <TextResponseView id={id} updateId={updateId} isDarkTheme={isDarkTheme} useCustomScore={question.useCustomScore} answerString={question.answerString} studentAnswer={question.studentAnswer} pointsEarned={question.pointsEarned} grading={grading} key={question.uuid} uuid={question.uuid} questionNumber={index} points={question.points} question={question.question}/>
            }
            case TypesOfQuestionsEnum.SCAN_AND_GRADE: {
                return <CompleteOnPaperView id={id} updateId={updateId} isDarkTheme={isDarkTheme} updatePdfUrls={updatePdfUrls} pdfUrls={pdfUrls} pointsEarned={question.pointsEarned} grading={grading} key={question.uuid} uuid={question.uuid} questionNumber={index} points={question.points} question={question.question}/>
            }
            case TypesOfQuestionsEnum.MULTIPLE_CHOICE: {
                return <MultipleChoiceView id={id} updateId={updateId} isDarkTheme={isDarkTheme} useCustomScore={question.useCustomScore} studentAnswer={question.studentAnswer} answerIndexAnswer={question.answerIndex} pointsEarned={question.pointsEarned} grading={grading} key={question.uuid} uuid={question.uuid} questionNumber={index} points={question.points} question={question.question} options={question.options}/>
            }
            case TypesOfQuestionsEnum.PROGRAMMING: {
                return <CodeResponseView status={status} endedExam={endedExam} error={error} tests={question.tests} testsConfigDict={testsConfigDict} id={id} updateId={updateId} ignoredPaths={ignoredPaths} ignoredPrefixes={ignoredPrefixes} requestLifeCycleTwo={requestLifeCycleTwo} updateContents={updateContents} useSyntaxHighlighting={useSyntaxHighlighting} studentAnswer={question.studentAnswer} pointsEarned={question.pointsEarned} contents={contents} grading={grading} uuid={question.uuid} location={question.location} useAutocompletion={question.useAutocompletion} key={question.uuid} questionNumber={index} points={question.points} question={question.question} language={question.language} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme}/>
            }
            case TypesOfQuestionsEnum.CONTEXT_BLOCK: {
                return <ContextBlockView uuid={question.uuid} isDarkTheme={isDarkTheme} key={question.uuid} questionNumber={index} points={question.points} question={question.question}/>
            }
            case TypesOfQuestionsEnum.LAMBDA: {
                return <LambdaResponseView status={question.status} allowedAttempts={question.allowedAttempts} endedExam={endedExam} testsMap={question.testsMap} results={question.results} useCustomScore={question.useCustomScore} useAutocompletion={question.useAutocompletion} initAnswer={question.initAnswer} id={id} updateId={updateId} useSyntax={question.useSyntax} requestLifeCycleTwo={requestLifeCycleTwo} useSyntaxHighlighting={useSyntaxHighlighting} studentAnswer={question.studentAnswer} pointsEarned={question.pointsEarned} grading={grading} uuid={question.uuid} key={question.uuid} questionNumber={index} points={question.points} question={question.question} language={question.language} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme}/>
            }
            case TypesOfQuestionsEnum.BANK_OF_QUESTIONS: {
                if (question.questions.length === 0) {
                    return <></>
                }
                const ajdustedIndex = index - 1
                if (ajdustedIndex >= arrayOfIndexes.length) {
                    const randomIndex = Math.floor(Math.random() * question.questions.length)
                    updateArrayOfIndexes(randomIndex)
                    return typeOfQuestionToReturn(question.questions[randomIndex], index);
                } else {
                    return typeOfQuestionToReturn(question.questions[arrayOfIndexes[ajdustedIndex]], index);
                }
            }
            default: {
                return <></>
            }
        }
    }

    const handleClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}`)
        }        
    }

    const findSingleQ = () => {
        const index = questions.findIndex((question) => question.uuid === id);
    
        if (index !== -1) {
            const q = questions[index];

            return typeOfQuestionToReturn(q, index + 1);
        }
    
        return <NotFoundSingleQ id={id} updateId={updateId}/>;
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(locationURL.search)
        const uuid = searchParams.get("uuid")

        if (uuid && questions) {
            // could also have id bottom of page
            let element = null;

            const escapedSelector = CSS.escape(uuid);
            element = document.querySelector("#" + escapedSelector + "-preview");    

            if (element) {
                element.scrollIntoView({ behavior: "instant" });
            }
        }
    }, [])

    return (
        <>
            {
                questions && Object.keys(questions).length > 0

                ?

                <>
                    {
                        id ?

                        <div className="custom-exam-wrapper-view single-q-wrapper" style={{ height: "fit-content" }}>
                            {
                                findSingleQ()
                            }
                        </div>

                        :

                        <>
                            <div className="custom-exam-wrapper-view">
                                {
                                    questions
                                        .map((question, index) => (
                                                typeOfQuestionToReturn(question, index + 1)
                                        )                                    
                                    )
                                }                 
                            </div>
                        </>
                    }                    
                </>

                :

                <>
                    <div className="no-questions">
                        <div className="no-assignments-container" style={{ paddingTop: "0px"}}>
                            <div className="no-assignments"> 
                                There are currently no { grading ? "answers to grade" : "questions to preview" }
                                <div className="save-changes-2" onClick={(e) => {
                                    if (grading) {
                                        handleClick(e)
                                    } else {
                                        updateIsEditing(true);
                                        updateAddAQuestion(true);
                                    }
                                }}>
                                    { grading ? "Back To Evaluation" : "Create A Question" }
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default CustomExamWrapperView;