import React, { useState } from "react";
import SaveChanges from "./saveChanges";
import { useParams } from "react-router-dom";
import WhiteLoading from "./whiteLoading";
import Info from "./info";

const RemoveSubForm = (props) => {
    const [text, setText] = useState("Are you sure you want to delete this submission?");
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const { courseCode, assessmentCode } = useParams();

    const resetForm = () => {
        props.updateDeleteSubmission(false);
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if (requestLifeCycle) {
            return;
        }

        try {
            setRequestLifeCycle(true)
            const url = process.env.REACT_APP_SUBMISSION_API_URL + "/delete-submission";
            const urlOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify({
                    courseCode: courseCode,
                    assessmentCode: assessmentCode,
                    email: props.email
                })
            }

            const response = await fetch(url, urlOptions);
            if (response.status === 200) {
                window.location.reload();
                setText("Success");
                props.updateDeleteSubmission(false);
            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            } else {
                setText("An error occurred")
            }

        } catch (error) {
            setText("An error occurred")
            console.log(error);
        } finally {
            setRequestLifeCycle(false)
        }
    }

    return (
        <>
            <div className="black-back" onClick={ () => {props.updateDeleteSubmission(false)} }>

            </div>
            <div className="add-a-course-form-container">
                <div className="add-a-course-form-content-container">
                    <form className="form-width">
                        <div className="add-course-title">
                            Remove Submission
                        </div>
                        <div className="account-settings-content">
                            <div className="required-container" style={{ maxWidth: "100%", overflow: "auto" }}>
                                { props.email }
                            </div>   
                            <div className="banner">
                                {
                                    requestLifeCycle 

                                    ?
                                    
                                    <>
                                        <WhiteLoading />
                                    </>

                                    :

                                    <div className="banner-info">
                                        <Info />
                                        { text }
                                    </div>

                                }
                            </div>
                            <SaveChanges left="Cancel" right={{ small : "Delete", large : "Delete Submission" }} resetForm={resetForm} submitForm={submitForm}/> 
                        </div>
                    </form> 
                </div>
            </div>
        </>
    );
}

export default RemoveSubForm;