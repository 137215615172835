import React, { useState } from "react";
import CourseInput from "./courseInput";
import SaveChanges from "./saveChanges";
import WhiteLoading from "./whiteLoading";
import moment from "moment-timezone";
import YearAndTerm from "./yearAndTerm";
import AddCourseDescription from "./addCourseDescription";
import { useParams, useNavigate } from "react-router-dom";
import Info from "./info";

const DuplicateForm = (props) => {
    const [text, setText] = useState("Duplicate all evaluations and course information");
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const navigate = useNavigate();
    const { courseCode } = useParams();
    const [form, setForm] = useState({
        courseCode: courseCode,
        deleteVal: "",
        deleteBool: false,
        name: props.name,
        description: props.description,
        term: props.term,
        year: props.year,
    })
    
    const resetForm = () => {
        props.updateDuplicate(false);
    }

    const submitForm = async (e) => {
        e.preventDefault()

        if (requestLifeCycle) {
            return;
        }

        if (form.name.length > 150) {
            setText("Course name must be 149 characters or fewer")
            return;
        }

        if (form.description.length > 200) {
            setText("Description must be 199 characters or fewer")
            return;
        }
        
        const url = process.env.REACT_APP_COURSE_API_URL + "/duplicate-course"

        const urlOptions = {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                courseCode: form.courseCode,
                name: form.name,
                description: form.description,
                year: form.year,
                term: form.term,
                timeZone: moment.tz.guess(),
                assessmentObjects: props.assessmentObjects

            })
        }

        try {
            setRequestLifeCycle(true)
            const res = await fetch(url, urlOptions);
            if (res.status === 200) {
                setText("Course duplicated")
                props.updateDuplicate(false);
                navigate("/")
            } else if (res.status === 410) {
                setText("Please try again")
            } else if (res.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL
            } else {
                setText("An error occurred")
            }
    
        } catch (error) {
            setText("An error occurred")
            console.log(error);
        } finally {
            setRequestLifeCycle(false)
        }
    }

    function updateName(name) {
        setForm(prevForm => ({
            ...prevForm,
            name: name,
        }))
    }

    function updateDescription(description) {
        setForm(prevForm => ({
            ...prevForm,
            description: description,
        }))
    }

    function updateYear(year) {
        setForm(prevForm => ({
            ...prevForm,
            year: year,
        }))
    }

    function updateTerm(term) {
        setForm(prevForm => ({
            ...prevForm,
            term: term,
        }))
    }

    return (
        <>
            <div className="black-back" onClick={ () => {props.updateDuplicate(false)} }>

            </div>
            <div className="add-a-course-form-container">
                <div className="add-a-course-form-content-container">
                    <form className="form-width">
                        <div className="add-course-title">
                            Duplicate Course
                        </div>
                        <div className="account-settings-content">
                            <div className="required-container">
                                { props.name }
                            </div>   
                            <div className="banner">                                
                                {
                                    requestLifeCycle 

                                    ?
                                    
                                    <>
                                        <WhiteLoading />
                                    </>

                                    :

                                    <div className="banner-info">
                                        <Info />
                                        { text }
                                    </div>

                                }
                            </div>
                            <CourseInput value={form.name} updateValue={updateName} top={"Course Name"} min={""} type={"text"} required={true} autoComplete={"one-time-code"} placeholder={"Ex. Math 233: Calculus of Functions of Several Variables"}/>
                            <AddCourseDescription value={form.description} updateValue={updateDescription} top={"Course Description"} required={true}/>    
                            <YearAndTerm year={form.year} term={form.term} updateTerm={updateTerm} updateYear={updateYear} required={false}/>
                            <SaveChanges left="Cancel" right={{ small : "Duplicate Course", large : "Duplicate Course" }} resetForm={resetForm} submitForm={submitForm}/> 
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default DuplicateForm;