import React, { useState, useEffect } from "react";
import useWindowWidth from "./useWindowWidth";
import { useParams } from "react-router-dom";

const TopRightCustomExam = ({ updateQuestionsToSave, questionsToSave, updateIsEditing, isEditing, updateAddAQuestion }) => {
    const width = useWindowWidth(575);
    const [text, setText] = useState("Save")
    const { courseCode, assessmentCode } = useParams();

    const saveQuestions = async () => {
        if (text === "Saving") {
            return;
        }

        const url = process.env.REACT_APP_EVALUATION_API_URL + "/update-questions"
        const urlOptions = {
            method: "POST",
            credentials: "include",
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify({
                courseCode: courseCode,
                assessmentCode: assessmentCode,
                questions: Object.keys(questionsToSave).map(key => questionsToSave[key])
            })
        }

        try {
            setText("Saving")
            const response = await fetch(url, urlOptions);
            if (response.status === 200) {
                updateQuestionsToSave({});
                setText("Save");
            } else if (response.status === 401) {
                setText("Failed");
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL
            } else {
                setText("Failed");
            }
        } catch (error) {
            setText("Failed");
            console.log(error)
        }
    }

    useEffect(() => {
        if (text === "Failed") {
            const timer = setTimeout(() => {
                setText("Save");
            }, 2000);
    
            // Cleanup the timeout on component unmount or when text changes
            return () => clearTimeout(timer);
        }
    }, [text]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (Object.keys(questionsToSave).length > 0) {
                // Optionally, you can show a confirmation message if needed
                const confirmationMessage = "You have unsaved changes. Are you sure you want to leave?";
                e.returnValue = confirmationMessage; // Standard for most browsers
                return confirmationMessage; // For some browsers
            }            
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [questionsToSave]); // Ensure this runs when feedback changes

    return (
        <>
            <div className="top-right-custom-wrapper">
                {
                    questionsToSave && Object.keys(questionsToSave).length > 0

                    ?

                    <>
                        <div className="top-right-item" onClick={() => {
                            saveQuestions();
                        }} style={{ width: width ? "40px" : "" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"/>
                            </svg>
                            { width ? "" : text}
                        </div>
                    </>

                    :

                    <>
                    </>

                }
                <div className="top-right-item" onClick={() => {
                    updateIsEditing(true);
                    updateAddAQuestion(true);
                }} style={{ width: width ? "40px" : "" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
                    </svg>
                    { width ? "" : "Question"}
                </div>
                <div className="top-right-item" onClick={() => {
                    updateIsEditing((prev) => {
                        return !prev;
                    })
                }} style={{ width: width ? "40px" : "" }}>
                    {   
                        isEditing

                        ?

                        <div className="top-right-item" style={{ width: width ? "30px" : "" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M4 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4m2.625.547a3 3 0 0 0-5.584.953H.5a.5.5 0 0 0 0 1h.541A3 3 0 0 0 7 8a1 1 0 0 1 2 0 3 3 0 0 0 5.959.5h.541a.5.5 0 0 0 0-1h-.541a3 3 0 0 0-5.584-.953A2 2 0 0 0 8 6c-.532 0-1.016.208-1.375.547M14 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0"/>
                            </svg>
                            { width ? "" : "Preview"}
                        </div>

                        :

                        
                        <>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M3.5 2a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-12a.5.5 0 0 0-.5-.5H12a.5.5 0 0 1 0-1h.5A1.5 1.5 0 0 1 14 2.5v12a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-12A1.5 1.5 0 0 1 3.5 1H4a.5.5 0 0 1 0 1z"/>
                                <path d="M10 .5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5.5.5 0 0 1-.5.5.5.5 0 0 0-.5.5V2a.5.5 0 0 0 .5.5h5A.5.5 0 0 0 11 2v-.5a.5.5 0 0 0-.5-.5.5.5 0 0 1-.5-.5"/>
                            </svg>
                            { width ? "" : "Edit"}
                        </>

                    }
                </div>
            </div>
        </>
    );
}

export default TopRightCustomExam;