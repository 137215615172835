const markdownDocs = `
### Autograder Documentation

### Sections

- **Overview**
- **Zip File Requirements**
- **JSON File Requirements**
- **Example**

### Overview

This document provides an outline of the GoValuate Autograder, including required files, best practices, and an explanation of the autograder run-time environment.

### Zip File Directory Structure

The following directory tree illustrates the requirements of an uploaded zip file for the autograder. The location of the \`tests_config.json\`
file must remain the same after your scripts have finished execution:

\`\`\`plaintext
root/
├── setup.sh (required)
├── tests_config.json (required)
├
├── <files for running tests (called by setup.sh)> (optional)
├── <files for question mapping> (optional)
\`\`\`

The GoValuate Autograder uses the official Python 3.12 Alpine Linux base image. 
There is a 10 minute execution time limit, 20 Gigabytes of ephemeral storage, 
3 Gigabytes of RAM, and 1 vCPU allocated for each short-lived invocation of the autograder. However, an uploaded starter 
zip file cannot exceed 5MB in size, and the final zip file (after a student has 
entered answers) may not exceed 6MB. The setup.sh file is the entry point of your scripts
that will be called upon build of the custom GoValuate container image. You may 
use \`apk add\` in your setup.sh file to install any necessary dependencies
that are required to run your test files. Dependencies should be installed 
by your scripts at runtime, not packaged within the zip file. All of your files will be stored at
\`/app/autograder/\`. After installation of dependencies, your setup.sh file 
should call your test files. The \`tests_config.json\` is the destination for 
the results of your tests, which should be within the initial zip file uploaded before
your evaluation is taken (see more in part 3). The \`tests_config.json\` file cannot exceed ~256KB in size after the autograder finishes. The \`tests_config.json\` file and 
\`setup.sh\` file should always be at the root of the zip file. \`Bash\` is not 
available in the container by default: you may use \`#!/bin/ash\` or \`#!/bin/sh\` 
in your \`setup.sh\`, as the ash shell is the default. Note: there is no need to call 
\`apk update\`, as the autograder will do this automatically before running your scripts. If your \`setup.sh\` exits with a non-zero code,
the standard output (stdout) and the standard error (stderr) will be returned and not any JSON results.

### tests_config.json

Initial tests_config.json file within the uploaded zip file (note: when a student starts the evalaution, 
the zip file is forked, and any subsequent changes to the evaluation zip file will not affect any existing student 
zip file submissions, finished or in progress):

\`\`\`json
{
  "ignore": [                       (required: may have length zero)
    <string>,
    <string>,
    ...,
    <string>
  ],
  "tests": [                        (required: may have length zero)
    {
        "name": <string>,           (required)
        "totalPoints": <float>,     (required)
        "id": <string>              (required)
    },
    {
        "name": <string>,
        "totalPoints": <float>,
        "id": <string>
    },    
    ...,
    {
        "name": <string>,
        "totalPoints": <float>,
        "id": <string>,
    }
  ]
}
\`\`\`

Final tests_config.json file returned after setup.sh finishes execution:

\`\`\`json
{
  "ignore": [
    <string>,
    <string>,
    ...,
    <string>
  ],
  "tests": [
    {
        "name": <string>,        
        "pointsEarned": <float>, (optional: will default to 0.0)
        "totalPoints": <float>,  
        "output": <string>,      (optional)
        "id": <string>           
    },
    {
        "name": <string>,
        "pointsEarned": <float>,
        "totalPoints": <float>,
        "output": <string>,
        "id": <string>
    },    
    ...,
    {
        "name": <string>,
        "pointsEarned": <float>,
        "totalPoints": <float>,
        "output": <string>,
        "id": <string>,
    }
  ]
}
\`\`\`

The ignore array will store strings of full paths to files
relative to the zip file root. Use forward slashes to deliminate 
file hierarchy. The path should NOT start with a forward slash or a . (e.g. ./) but rather the name of
the root file or directory at the beginning of the desired path. Each string will have the effect 
of popping its specified file from student viewing and access.
A question cannot map to an ignored file. You may ignore an entire directory 
(its dirname and all of its contents) with the wildcard character \`*\` 
(e.g. will only ignore the subdirectory not its parent directory: <dir_1_at_root>/<sub_dir_inside_dir_1>/*). Furthermore, each id 
should be universally unique within the tests array. The ignore and tests 
arrays may have length zero but are required. Finally, 
the initial json version is used for question mapping, and the final json version is 
used for displaying results to the student or instructor, as well as grading and assigning points to questions.

### Example

Zip File Directory Structure:

\`\`\`plaintext
root/
├── setup.sh             (will call test_controller.py)
├── tests_config.json
├
├── test_controller.py
├── question_one.py      (mapping file for user input)
\`\`\`

Initial tests_config.json file within the uploaded zip file:

\`\`\`json
{
  "ignore": [
    "setup.sh",
    "test_controller.py"
  ],
  "tests": [
    {
        "name": "Test 1",
        "totalPoints": 2.0,
        "id": "0"
    },
    {
        "name": "Test 2",
        "totalPoints": 1.0,
        "id": "1"
    },
    {
        "name": "Test 3",
        "totalPoints": 3.0,
        "id": "2"
    }
  ]
}
\`\`\`

The file-location and test-selection mapping will take place during the 
evaluation build process on GoValuate within the edit evaluation section 
(after a student fork, changing question-to-file locations will affect existing submissions, 
but changing test-selection mapping for individual questions will not affect existing submissions). 
Multiple questions can map to the same file path within the zip file.
You may include starter code within your files that will be available 
to students upon the start of the evaluation. When a question maps to a file
within the zip file, this will save user input to that file location, acting as a pointer. Once the student submits
their evaluation, the complete zip file, which contains user code input, will be autograded according to the 
tests you define within your zip file testing code.


`;

export default markdownDocs;