import React, { useState, useEffect } from "react";
import Navigation from "./navigation";
import { useParams, useNavigate } from "react-router-dom"; // need for api call later
import DownArrow from "./downArrow";
import useScrollToTop from "./useScrollToTop";
import NavInfoSubmissions from "./navInfoSubmissions";
import useWindowWidth from "./useWindowWidth";
import StudentSubmission from "./studentSubmission";
import Materials from "./materials";
import PersonFill from "./personFill";
import Loading from "./loading";
import DeleteEvaluationForm from "./deleteEvaluationForm";
import Search from "./search";
import moment from "moment-timezone";
import NavInfoSubmissionsCollapsed from "./navInfoSubmissionsCollapsed";
import RemoveSubForm from "./removeSubForm";

const SubmissionDashboard = () => {
    useScrollToTop();
    const navigate = useNavigate();
    // eslint-disable-next-line
    const { courseCode, assessmentCode } = useParams(); // need for api call later
    const width = useWindowWidth(800);
    const widthTwo = useWindowWidth(550);
    const widthFour = useWindowWidth(800);
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [assessment, setAssessment] = useState({});
    const [submissionsArr, setSubmissionsArr] = useState([]);
    const [deleteEvaluation, setDeleteEvaluation] = useState(false);
    const [searchName, setSearchName] = useState("");
    const [deleteSubmission, setDeleteSubmission] = useState(false);
    const [email, setEmail] = useState("");
    const [nameFlip, setNameFlip] = useState(false);
    const [statusFlip, setStatusFlip] = useState(false);
    const [startTimeFlip, setStartTimeFlip] = useState(false);
    const [endTimeFlip, setEndTimeFlip] = useState(false);

    function updateDeleteSubmission(bool) {
        setDeleteSubmission(bool);
    }

    function updateEmail(email) {
        setEmail(email);
    }

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                setRequestLifeCycle(true);
                const res = await fetch( process.env.REACT_APP_EVALUATION_API_URL + `/get-assessment-submissions/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await res.json();                
                if (res.status === 200) {
                    setAssessment(data)
                    setSubmissionsArr([...data.submissions].sort());
                } else if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                }
        
            } catch (error) {
                console.log(error);
            } finally {
                setRequestLifeCycle(false);
            }
        }

        fetchCourse();
    }, [])

    const changeStatusSortStartTime = () => {       
        let sortOrder = null;

        for (let i = 0; i < submissionsArr?.length; i++) {
            let l = i - 1;

            if (i > 0 && moment(submissionsArr[l].startTime).isAfter(moment(submissionsArr[i].startTime))) {
                sortOrder = true;
                break;
            }
        }

        const sortedSubmissions = [...submissionsArr].sort((a, b) => {
            if (sortOrder) {
                return moment(a.startTime).isBefore(moment(b.startTime)) ? -1 : 1;
            } else {
                return moment(a.startTime).isAfter(moment(b.startTime)) ? -1 : 1;
            }
        });        
        setSubmissionsArr(sortedSubmissions);

        setStartTimeFlip(prev => !prev);
        setNameFlip(false);
        setStatusFlip(false);
        setEndTimeFlip(false);
    }

    const changeStatusSortEndTime = () => {    
        let sortOrder = null;

        for (let i = 0; i < submissionsArr?.length; i++) {
            let l = i - 1;

            if (i > 0 && moment(submissionsArr[l].endTime).isAfter(moment(submissionsArr[i].endTime))) {
                sortOrder = true;
                break;
            }
        }

        const sortedSubmissions = [...submissionsArr].sort((a, b) => {
            if (sortOrder) {
                return moment(a.endTime).isBefore(moment(b.endTime)) ? -1 : 1;
            } else {
                return moment(a.endTime).isAfter(moment(b.endTime)) ? -1 : 1;
            }
        });        
        setSubmissionsArr(sortedSubmissions);

        setEndTimeFlip(prev => !prev);
        setNameFlip(false);
        setStartTimeFlip(false);
        setStatusFlip(false);
    }

    const changeGradedStatusSort = () => {
        let sortOrder = null;

        if (submissionsArr && submissionsArr.length > 0) {
            sortOrder = submissionsArr[0].endedExam;
        }

        const sortedSubmissions = [...submissionsArr].sort((a, b) => {
            if (sortOrder) {
                return a.endedExam - b.endedExam;
            } else {
                return b.endedExam - a.endedExam;
            }
        });

        setSubmissionsArr(sortedSubmissions);
        
        setStatusFlip(prev => !prev);
        setEndTimeFlip(false);
        setNameFlip(false);
        setStartTimeFlip(false);
    }

    const handleDetailsClick = (event) => {
        if (event.metaKey || event.ctrlKey) {let sortOrder = null;            
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/settings`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/settings`);
        }
    }

    const handleStatisticsClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/settings`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/statistics`);
        }
    }

    const handleDashboardClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/settings`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/`);
        }
    }

    function updateDeleteEvaluation(deleteVal) {
        setDeleteEvaluation(deleteVal);
    }

    function updateSearchName(e) {
        setSearchName(e.target.value);
    }

    const sortName = () => {
        let sortOrder = null;

        for (let i = 0; i < submissionsArr?.length; i++) {
            let l = i - 1;

            if (i > 0 && submissionsArr[l].email.localeCompare(submissionsArr[i].email) > 0) {
                sortOrder = true;
                break;
            }
        }

        const sortedArr = [...submissionsArr].sort((a, b) => {
            if (sortOrder) {
                return a.email.localeCompare(b.email);  // Sort in ascending order
            } else {
                return b.email.localeCompare(a.email);  // Sort in descending order
            }
        });
    
        setSubmissionsArr(sortedArr);

        setNameFlip(prev => !prev);
        setEndTimeFlip(false);
        setStartTimeFlip(false);
        setStatusFlip(false);
    };
    
    return (
        <>
            <Navigation isProgramming={assessment.isProgramming} boolFour={false} boolThree={false} boolTwo={false} bool={false} info={ <NavInfoSubmissions isProgramming={assessment.isProgramming} handleDashboardClick={handleDashboardClick} updateDeleteEvaluation={updateDeleteEvaluation} handleStatisticsClick={handleStatisticsClick} handleDetailsClick={handleDetailsClick} name={assessment.name} />} infoCollapsed={ <NavInfoSubmissionsCollapsed handleDashboardClick={handleDashboardClick} updateDeleteEvaluation={updateDeleteEvaluation} handleStatisticsClick={handleStatisticsClick} handleDetailsClick={handleDetailsClick}/>}/>
            <div className="courses-container">
                <div className="header">
                    <div className="single-course-container">
                        <div className="your-course" style={{ position: "relative" }}>
                            <div className="course-metadata" style={{ flexDirection: width ? "column" : "row", alignItems:  width ? "flex-start" : "center" }}> 
                                {
                                    assessment && Object.keys(assessment).length > 0

                                    ?

                                    <>
                                        <div className={`course-name ${width ? (widthTwo ? "overflow-name-small-small" : "overflow-name-small") : "overflow-name"}`}>
                                            { assessment.name }
                                        </div>
                                        { width ? <></> : <div className="bar"></div> }
                                        <div className="font three overflow-code">
                                            <PersonFill /> { submissionsArr.length } { submissionsArr.length === 1 ? "Submission" : "Submissions"}
                                        </div>
                                        <div className={ widthTwo ? "search-holder-bottom" : "search-holder"} style={{width: "180px"}}>
                                            <Search searchName={searchName} updateSearchName={updateSearchName}/>
                                        </div>
                                    </>

                                    :

                                    <>
                                    </>

                                }
                            </div>
                            <div className="course-metadata-2"> 
                                <div className="roster-meta">
                                    {
                                        widthFour

                                        ?

                                        <>
                                            <div className="roster-meta-title" style={{ width: "50%", marginRight: "20px" }}>
                                                <div className="status-container" onClick={ sortName } style={{ userSelect: "none" }}>
                                                    <DownArrow flip={nameFlip}/>
                                                    Name 
                                                    <Materials />
                                                </div>
                                            </div>
                                            <div className="roster-meta-title" style={{ width: "50%", justifyContent: "flex-end", userSelect: "none" }}>
                                                <div className="roster-meta-title" style={{ width: "fit-content", justifyContent: "flex-end", cursor: "pointer" }} onClick={changeGradedStatusSort}>
                                                    <DownArrow flip={statusFlip}/>
                                                    Status
                                                </div>
                                            </div>
                                        </>

                                        :
                                        
                                        <>
                                            <div className="roster-meta-title" style={{ marginRight: "20px", width: "35%" }}>
                                                <div className="status-container" onClick={ sortName } style={{ userSelect: "none" }}>
                                                    <DownArrow flip={nameFlip}/>
                                                    Name 
                                                    <Materials />
                                                </div>
                                            </div>
                                            <div className="roster-meta-title" style={{ userSelect: "none", width: "30%" }}>
                                                <div className="roster-meta-title" style={{ width: "fit-content", justifyContent: "flex-end", cursor: "pointer" }} onClick={changeGradedStatusSort}>
                                                    <DownArrow flip={statusFlip}/>
                                                    Status
                                                </div>
                                            </div>
                                            <div className="roster-meta-title" style={{ userSelect: "none", width: "20%" }}>
                                                <div className="roster-meta-title three" style={{ cursor: "pointer", width: "fit-content" }} onClick={ changeStatusSortStartTime }>
                                                    <DownArrow flip={startTimeFlip}/>
                                                    Start Time
                                                </div>
                                            </div>
                                            <div className="roster-meta-title three" style={{ userSelect: "none" }}>
                                                <div className="roster-meta-title three" style={{ cursor: "pointer", width: "fit-content" }} onClick={ changeStatusSortEndTime }>
                                                    <DownArrow flip={endTimeFlip}/>
                                                    End Time
                                                </div>
                                            </div>
                                        </>
                                        
                                    
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="exams-container">
                            {
                                requestLifeCycle 

                                ?

                                <>
                                    <div className="loading-container" style={{ height: "200px" }}>
                                        <Loading />
                                    </div>  
                                </>

                                :

                                <>
                                    {
                                        submissionsArr && submissionsArr.length > 0

                                        ?

                                        <>
                                            {
                                                submissionsArr.map((submission) => {
                                                    // status either Ungraded with blue or the grade with green
                                                    if (submission.name.toLowerCase().includes(searchName.toLowerCase())) {
                                                        return <StudentSubmission updateEmail={updateEmail} updateDeleteSubmission={updateDeleteSubmission} endTime={submission.endTime} startTime={submission.startTime} key={submission.email} endedExam={submission.endedExam} email={submission.email} name={submission.name} />
                                                    }
                                                })
                                            }
                                        </>

                                        :

                                        <>
                                            <div className="no-assignments-container">
                                                <div className="no-assignments"> 
                                                    Your evaluation currently has no submissions
                                                    <div className="save-changes-2" onClick={ handleDetailsClick } style={{ width: "200px" }}>
                                                        View Evaluation Settings
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }       
                                </>                                

                            }
                        </div>
                    </div>
                </div>
            </div>
            { deleteEvaluation ? <DeleteEvaluationForm name={assessment.name} updateDeleteEvaluation={updateDeleteEvaluation} /> : <></> }
            { deleteSubmission ? <RemoveSubForm email={email} updateDeleteSubmission={updateDeleteSubmission}/> : <></> }
        </>
    );
}

export default SubmissionDashboard;