import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";


const findTestsFromQsAndBankedQs = (questionsInOrder) => {
    const updatedQuestions = [];

    questionsInOrder.forEach((question, index) => {
        index += 1
        question.index = index; // Update main question index

        if (question.questionType === TypesOfQuestionsEnum.BANK_OF_QUESTIONS) {
            question.questions.forEach((nestedQuestion, nestedIndex) => {
                nestedIndex += 1
                if (nestedQuestion.questionType === TypesOfQuestionsEnum.PROGRAMMING && nestedQuestion.tests.length > 0) {
                    nestedQuestion.index = `${index}.${nestedIndex}`; // Update nested question index
                    updatedQuestions.push(nestedQuestion); // Add nested programming question
                }
            });
        }

        if (question.questionType === TypesOfQuestionsEnum.PROGRAMMING && question.tests.length > 0) {
            updatedQuestions.push(question); // Add main programming question
        }
    });
    
    return updatedQuestions;
}

export default findTestsFromQsAndBankedQs;