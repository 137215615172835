import React, { useState, useEffect } from "react";
import NavIcon  from "./navIcon";

const SideNav = (props) => {
    const [info, setInfo] = useState(false);

    useEffect(() => {
        let timeout;
    
        if (props.open) {
            timeout = setTimeout(() => {
                setInfo(true);
            }, 400);
        } else {
            setInfo(false);
        }
    
        return () => clearTimeout(timeout);
    }, [props.open]);

    return (
        <>
            {
                props.open

            ?
            
                <div className={ "side-nav-container-open" }>
                    <NavIcon updateOpen={props.updateOpen} open={props.open} info={info}/>
                    { info ? props.info : "" }
                    {
                        !props.isHolding && (
                            <div className="outside-click" onClick={() => {props.updateOpen(false)}}/>
                        )
                    }
                </div>

            :
            
                <div className="side-nav-container">
                    <NavIcon updateOpen={props.updateOpen} open={props.open}/>
                    { props.infoCollapsed }
                    <div className="outside-click-side-nav" onClick={() => {props.updateOpen(true)}}/>
                </div>
            }
        </>
    );
}

export default SideNav;