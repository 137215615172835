export const languageReadableMap = {
    'ABAP': 'abap',
    'Apex': 'apex',
    'Azure CLI': 'azcli',
    'Batch': 'bat',
    'Bicep': 'bicep',
    'C': 'c',
    'Cameligo': 'cameligo',
    'Clojure': 'clojure',
    'CoffeeScript': 'coffeescript',
    'C++': 'cpp',
    'C#': 'csharp',
    'CSS': 'css',
    'Dart': 'dart',
    'Dockerfile': 'dockerfile',
    'ECL': 'ecl',
    'Elixir': 'elixir',
    'F#': 'fsharp',
    'Go': 'go',
    'GraphQL': 'graphql',
    'HCL': 'hcl',
    'HTML': 'html',
    'INI': 'ini',
    'Java': 'java',
    'JavaScript': 'javascript',
    'Julia': 'julia',
    'Kotlin': 'kotlin',
    'Less': 'less',
    'Lexon': 'lexon',
    'Lua': 'lua',
    'Markdown': 'markdown',
    'M3': 'm3',
    'MySQL': 'mysql',
    'Objective-C': 'objective-c',
    'Pascal': 'pascal',
    'Pascaligo': 'pascaligo',
    'Perl': 'perl',
    'PostgreSQL': 'pgsql',
    'PHP': 'php',
    'Plaintext': 'plaintext',
    'Power Query': 'powerquery',
    'PowerShell': 'powershell',
    'Protocol Buffers': 'proto',
    'Pug': 'pug',
    'Python': 'python',
    'Q#': 'qsharp',
    'R': 'r',
    'Razor': 'razor',
    'Redis': 'redis',
    'Redshift': 'redshift',
    'reStructuredText': 'restructuredtext',
    'Ruby': 'ruby',
    'Rust': 'rust',
    'Scala': 'scala',
    'Scheme': 'scheme',
    'SCSS': 'scss',
    'Shell': 'shell',
    'SPARQL': 'sparql',
    'SQL': 'sql',
    'Structured Text': 'st',
    'Swift': 'swift',
    'SystemVerilog': 'systemverilog',
    'TCL': 'tcl',
    'Twig': 'twig',
    'TypeScript': 'typescript',
    'VB': 'vb',
    'Verilog': 'verilog',
    'XML': 'xml',
    'YAML': 'yaml',
    'JSON': 'json'
  };  