import React, { useEffect, useState } from "react";
import CourseInput from "./courseInput";
import SaveChanges from "./saveChanges";
import AddCourseDescription from "./addCourseDescription";
import YearAndTerm from "./yearAndTerm";
import getCurrentSeason from "./getCurrentSeason";
import moment from "moment-timezone";
import WhiteLoading from "./whiteLoading";
import Info from "./info";

const AddACourseForm = (props) => {
    const [text, setText] = useState("Enter your course information below");
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [form, setForm] = useState({
        name: "",
        description: "",
        term: getCurrentSeason().split(" ")[0],
        year: moment().year(),
        timeZone: moment.tz.guess(),
    })

    function updateName(name) {
        setForm(prevForm => ({
            ...prevForm,
            name: name,
        }))
    }

    function updateDescription(description) {
        setForm(prevForm => ({
            ...prevForm,
            description: description,
        }))
    }

    const resetForm = () => {
        props.updateAdding(false);
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if (requestLifeCycle) {
            return;
        }

        if (form.name.length > 150) {
            setText("Course name must be 149 characters or fewer")
            return;
        }

        if (form.description.length > 200) {
            setText("Description must be 199 characters or fewer")
            return;
        }
        
        for (const value in form) {
            if (form[value] === "") {
                setText("Please fill out all form fields")
                return;
            }
        }

        if (form.name.trim() === "" || form.description.trim() === "") {
            setText("Remove whitespace");
            return;
        }

        const url = process.env.REACT_APP_COURSE_API_URL + "/create"

        const urlOptions = {    
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(form)
        }


        try {
            setRequestLifeCycle(true)
            const res = await fetch(url, urlOptions);
            if (res.status === 200) {
                setText("Course created")
                props.updateAdding(false);
                window.location.reload()
            } else if (res.status === 401) {
                setText("Authentication error")
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            } else if (res.status === 410) {
                setText("Please try again")
            } else {
                setText("An error occurred")
            }            

        } catch (error) {
            setText("An error occurred")
            console.log(error);
        } finally {
            setRequestLifeCycle(false);
        }
        
    }

    function updateYear(year) {
        setForm(prevForm => ({
            ...prevForm,
            year: year,
        }))
    }

    function updateTerm(term) {
        setForm(prevForm => ({
            ...prevForm,
            term: term,
        }))
    }

    useEffect(() => {
        setText("Enter your course information below")
    }, [form])

    return (
        <>
            <div className="black-back" onClick={ () => {props.updateAdding(false)} }>

            </div>
            <div className="add-a-course-form-container">
                <div className="add-a-course-form-content-container">
                    <form className="form-width">
                        <div className="add-course-title">
                            Create Your Course
                        </div>
                        <div className="account-settings-content">
                            <div className="required-container">
                                All Fields Required
                            </div>   
                            <div className="banner">
                                {
                                    requestLifeCycle 

                                    ?
                                    
                                    <>
                                        <WhiteLoading />
                                    </>

                                    :

                                    <div className="banner-info">
                                        <Info />
                                        { text }
                                    </div>

                                }
                            </div>
                            <CourseInput value={form.name} updateValue={updateName} top={"Course Name"} min={""} type={"text"} required={true} autoComplete={"one-time-code"} placeholder={"Ex. Math 233: Calculus of Functions of Several Variables"}/>
                            <AddCourseDescription value={form.description} updateValue={updateDescription} top={"Course Description"} required={true}/>    
                            <YearAndTerm year={form.year} term={form.term} updateTerm={updateTerm} updateYear={updateYear} required={false}/>
                            <SaveChanges left="Cancel" right={{ small : "Add Course", large : "Create Course" }} submitForm={submitForm} resetForm={resetForm}/> 
                        </div>
                    </form> 
                </div>
            </div>
        </>
    );
}

export default AddACourseForm;