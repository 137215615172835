import React, { useState, useEffect } from "react";
import Navigation from "./navigation";
import { useParams, useNavigate } from "react-router-dom"; // need for api call later
import useScrollToTop from "./useScrollToTop";
import NavInfoSubmissions from "./navInfoSubmissions";
import useWindowWidth from "./useWindowWidth";
import Graph from "./graph";
import BoxPlotCustom from "./boxPlotCustom";
import Loading from "./loading";
import SmallBoxPlot from "./smallBoxPlot";
import DeleteEvaluationForm from "./deleteEvaluationForm";
import NavInfoSubmissionsCollapsed from "./navInfoSubmissionsCollapsed";

const SubmissionStatistics = () => {
    useScrollToTop();
    const navigate = useNavigate();
    const { courseCode, assessmentCode } = useParams(); // need for api call later
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [deleteEvaluation, setDeleteEvaluation] = useState(false);
    const [data, setData] = useState({
        name: "",
        timeData: [],
        gradesData: [],
    });
    const width = useWindowWidth(800);
    const widthTwo = useWindowWidth(600);

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                setRequestLifeCycle(true);
                const res = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-evaluation-statistics/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await res.json();
                if (res.status === 200) {
                    setData(data)
                } else if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                }
        
            } catch (error) {
                console.log(error);
            } finally {
                setRequestLifeCycle(false);
            }
        }

        fetchCourse();
    }, [])

    const handleDetailsClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/settings`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/settings`);
        }
    }

    const handleStatisticsClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/settings`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/statistics`);
        }
    }

    const handleDashboardClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/settings`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/`);
        }
    }

    function updateDeleteEvaluation(deleteVal) {
        setDeleteEvaluation(deleteVal);
    }

    return (
        <>
            <Navigation boolThree={false} boolTwo={false} bool={false} info={ <NavInfoSubmissions isProgramming={data.isProgramming} updateDeleteEvaluation={updateDeleteEvaluation} handleDetailsClick={handleDetailsClick} handleDashboardClick={handleDashboardClick} handleStatisticsClick={handleStatisticsClick} name={data.name} /> } infoCollapsed={ <NavInfoSubmissionsCollapsed handleDashboardClick={handleDashboardClick} updateDeleteEvaluation={updateDeleteEvaluation} handleStatisticsClick={handleStatisticsClick} handleDetailsClick={handleDetailsClick}/>}/>
            <div className="courses-container">
                <div className="header">
                    <div className="single-course-container">
                        <div className="your-course">
                            <div className="course-metadata" style={{ flexDirection: width ? "column" : "row", alignItems:  width ? "flex-start" : "center" }}> 
                                {
                                    data && Object.keys(data).length > 0

                                    ?

                                    <> 
                                       <div className={`${width ? "overflow-name-small-small" : "overflow-name-stat"}`}>
                                            { data.name }
                                        </div>
                                        { width ? <></> : <div className="bar"></div> }
                                        <div className="font four overflow-code" style={{ flexWrap: "nowrap" }}>
                                            <Graph /> Statistics
                                        </div>
                                    </>

                                    :

                                    <>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="exams-container">
                            {
                                requestLifeCycle 

                                ?

                                <>
                                    <div className="loading-container" style={{ height: "200px" }}>
                                        <Loading />
                                    </div>  
                                </>

                                :

                                <>
                                    {

                                        data && data.timeData.length === 0 && data.gradesData.length === 0
                                        
                                        ?

                                        <div className="no-assignments-container">
                                            <div className="no-assignments"> 
                                                Your evaluation currently has no statistics because there are no submissions
                                                <div className="save-changes-2" onClick={ handleDetailsClick } style={{ width: "200px" }}>
                                                    View Evaluation Settings
                                                </div>
                                            </div>
                                        </div>

                                        :

                                        <>
                                            <div className="box-plot-container-submission">
                                                {
                                                    widthTwo 

                                                    ?

                                                    <>
                                                        <SmallBoxPlot timeData={data.timeData} gradesData={data.gradesData}/>
                                                    </>

                                                    :

                                                    <>
                                                        <BoxPlotCustom timeData={data.timeData} gradesData={data.gradesData}/>
                                                    </>

                                                }
                                            </div>
                                        </>

                                    }
                                </> 
                            }
                        </div>
                    </div>
                </div>
            </div>
            { deleteEvaluation ? <DeleteEvaluationForm name={data.name} updateDeleteEvaluation={updateDeleteEvaluation} /> : <></> }
        </>
    );
}

export default SubmissionStatistics;