import Tippy from "@tippyjs/react";
import React from "react";
import { useParams } from "react-router-dom";

const Regrade = ({ updateUseCustomScoreFromGrading, uuid }) => {
    const { courseCode, assessmentCode, email } = useParams();

    const handleClick = async () => {
        try {
            const url = process.env.REACT_APP_SUBMISSION_API_URL + "/delete-custom-score"
            const urlOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    courseCode: courseCode,
                    assessmentCode: assessmentCode,
                    email: email,
                    uuid: uuid
                })
            }

            const response = await fetch(url, urlOptions);
            if (response.status === 200) {
                updateUseCustomScoreFromGrading(false);
            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <Tippy placement="bottom" content="Revert custom score to autograding. Update on reload." theme="custom-tooltip">
                <div className="regrade" onClick={handleClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2z"/>
                        <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466"/>
                    </svg>
                </div>
            </Tippy>
        </>
    );
}

export default Regrade;