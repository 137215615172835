import React, { useState, useEffect } from "react";
import TypesOfLocationEnum from "./typesOfLocationEnum";
import WhiteLoading from "./whiteLoading";
import ASingleTestGrading from "./aSingleTestGrading";
import SelectZipFile from "./selectZipFile";
import { useParams } from "react-router-dom";
import uploadFileToS3 from "./uploadFileToS3";
import validateZipFile from "./validateZipFile";
import Tippy from "@tippyjs/react";

const UploadAndViewTests = ({ status, updateAttempt, attempt, autograderRequestLifeCycles, updateAutograderRequestLifeCycles, error, updateError, testsConfigDictForUpload, questionsForUpload, updateQuestionsForUpload, initQuestions, updateCurrentViewer }) => {
    const [inputZipFile, setInputZipFile] = useState("");
    const [text, setText] = useState(initQuestions && initQuestions.length > 0 ? "Upload a test zip file below" : "No questions to test at this time");
    const { courseCode, assessmentCode } = useParams();

    const updateZipFile = async (file) => {
        const [isValid, message] = await validateZipFile(file);
        
        if (!isValid) {
            setText(message);
            return;
        }

        setInputZipFile(file)
    }

    const uploadZipBeforeAutograde = async () => {
        try {
            const response = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-url-test-zip/${courseCode}/${assessmentCode}`, { method: "GET", credentials: "include" })
            const data = await response.json()

            if (response.status === 200 && data.url) {
                const status = await uploadFileToS3(inputZipFile, data.url, "application/zip");

                if (status !== 200) {
                    setText("Zip failed to upload");
                }

                return 200

            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_SUBMISSION_API_URL;
            } else {
                setText("Could not fetch signed url")
                return 400
            }
        } catch (error) {
            console.log(error)
            setText(error.message)            
            return 400
        }
    }

    useEffect(() => {
        const uploadAndTestZipFile = async () => {
            if (autograderRequestLifeCycles.requestLifeCycle) {
                return;
            }
            
            // max size
            const maxSizeBytes = 5 * 1024 * 1024;

            // form validation
            if (inputZipFile.size > maxSizeBytes) {
                setText("Zip File exceeds 5MB.")
                return;
            }

            try {
                updateAutograderRequestLifeCycles({
                    requestLifeCycle: true,
                    runningInBackground: false
                })
                const statusCode = await uploadZipBeforeAutograde();

                if (statusCode !== 200) {
                    return;
                }

                if (!inputZipFile) {
                    return;             
                }

                const url = process.env.REACT_APP_START_AUTOGRADER_API_URL
                const urlOptions = {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        courseCode: courseCode,
                        assessmentCode: assessmentCode,
                        email: "test"
                    })
                }

                const response = await fetch(url, urlOptions);
                const data = await response.json();
                if (response.status === 200) {             
                    updateAutograderRequestLifeCycles((prev) => ({
                        ...prev,
                        runningInBackground: true
                    }));
                } else if (response.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL
                } else {
                    updateAttempt(-1)
                    updateError(data.detail)
                }

            } catch (error) {
                updateAttempt(-1)
                updateError(error.message)
                console.log(error)
            } finally {
                updateAutograderRequestLifeCycles((prev) => ({
                    ...prev,
                    requestLifeCycle: false
                }))
            }
        }

        if (inputZipFile) {
            uploadAndTestZipFile();
        }

    }, [inputZipFile])

    useEffect(() => {
        setText(initQuestions && initQuestions.length > 0 ? "Upload a test zip file below" : "No questions to test at this time");
    }, [initQuestions])

    const findMessage = () => {
        if (status === "RUNNING") {
            return (
                <>
                    Clear Previous Result (Attempt {attempt} is processing)
                </>
            );
        }
    
        return (
            <>
                Clear {attempt !== -1 ? `Attempt ${attempt}` : "Request Error" }
            </>
        );
    }

    return (
        <>
            {
                (autograderRequestLifeCycles.requestLifeCycle || autograderRequestLifeCycles.runningInBackground) && !error

                ?

                <div className="loading-zip-container">
                    {
                        autograderRequestLifeCycles.runningInBackground ?

                        <>
                            <div className="message">
                                Autograder is running in the background. Reload periodically and wait for updates.
                                <div className="reload" onClick={() => window.location.reload()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
                                    </svg>
                                </div>
                            </div>
                        </>

                        :

                        <>
                            <WhiteLoading />
                        </>

                    }
                </div>

                :

                <>
                    {
                        (questionsForUpload && questionsForUpload.length > 0) || error

                        ?

                        <>
                            <div className="question-tests-container">
                                <div className="clear-container">
                                    <div className="top-right-item" onClick={() => {
                                        updateQuestionsForUpload([]);
                                        updateError(null);
                                    }} style={{ paddingTop: "10px", paddingBottom: "10px", height: "fit-content", width: "auto", paddingLeft: "30px", paddingRight: "30px" }}>
                                        {findMessage()}
                                    </div>
                                </div>
                                {
                                    questionsForUpload.map((question) => (
                                        question.tests.map((id) => {
                                            if (id in testsConfigDictForUpload) {
                                                return <ASingleTestGrading instructorPage={true} key={id} {...testsConfigDictForUpload[id]} index={question.index}/>
                                            }
                                        })
                                    ))
                                }
                                {
                                    error && (
                                        <div>
                                            {error}
                                        </div>
                                    )
                                }
                            </div>
                        </>

                        :

                        <>
                            <div className="no-questions">
                                <div className="no-assignments-container" style={{ paddingTop: "0px"}}>
                                    <div className="no-assignments">
                                        { text }
                                        {
                                            initQuestions && initQuestions.length > 0 ?

                                            <div className="input-wrapper-test-zip" style={{ position: "relative" }}>
                                                <div style={{ width: "80%", height: "27.5px" }}>
                                                    <SelectZipFile star={false} value={inputZipFile} updateValue={updateZipFile} required={true}/>
                                                </div>
                                                <Tippy placement="bottom" content="View results for saved tests that are mapped to questions" theme="custom-tooltip">
                                                    <div className="pdf-docs-wrapper-icon docs-wrapper-icon" style={{ position: "static" }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                                                            <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                                        </svg>
                                                    </div>
                                                </Tippy>
                                            </div>

                                            :

                                            <>
                                                <div className="save-changes-2" onClick={() => {
                                                    updateCurrentViewer(TypesOfLocationEnum.CUSTOM_EXAM);
                                                }}>
                                                    Back To Questions
                                                </div>
                                            </>

                                        }
                                    </div>
                                </div>
                            </div>
                        </>

                    }
                </>

            }
        </>
    );
}

export default UploadAndViewTests;