import React, { useState, useEffect } from "react";
import Navigation from "./navigation";
import useScrollToTop from "./useScrollToTop";
import { useParams, useNavigate, useLocation } from "react-router-dom"; // need for api call later
import NavInfoSubmissions from "./navInfoSubmissions";
import useWindowWidth from "./useWindowWidth";
import DeleteEvaluationForm from "./deleteEvaluationForm";
import NavInfoSubmissionsCollapsed from "./navInfoSubmissionsCollapsed";
import LeftInfoGradingPage from "./leftInfoGradingPage";
import LocationSettingsHover from "./locationSettingsHover";
import ZipFileViewerGradingPage from "./zipFileViewerGradingPage";
import TypesOfLocationEnum from "./typesOfLocationEnum";
import GradingPageCustomExamWrapper from "./gradingPageCustomExamWrapper";
import parseFileStructure from "./parseFileStructureToReturnPaths"
import parseZipFile from "./parseZipFile"
import fetchWithPreSigned from "./fetchWithPreSigned";
import fetchSubmissionPdf from "./fetchSubmissionPdf";
import findUuidsOfScanAndGrade from "./findUuidsOfScanAndGrade";
import retIgnoredPrefixes from "./retIgnorePrefixes";
import findStillProcessing from "./findStillProcessing";
import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";

const GradingPage = () => {
    useScrollToTop();
    const navigate = useNavigate();
    const width = useWindowWidth(1000);
    const { courseCode, assessmentCode, email } = useParams(); // need for api call later
    const [deleteEvaluation, setDeleteEvaluation] = useState(false);
    const [assessmentInformation, setAssessmentInformation] = useState({});
    const [isCursorInside, setIsCursorInside] = useState(false);
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [contentsForViewer, setContentsForViewer] = useState([]);
    const [blob, setBlob] = useState();
    const [requestLifeCycleTwo, setRequestLifeCycleTwo] = useState(false);
    const [contentsForCustomExam, setContentsForCustomExam] = useState({});
    const [currentViewer, setCurrentViewer] = useState("");
    const [testsConfigDict, setTestsConfigDict] = useState({});
    const [isDarkTheme, setIsDarkTheme] = useState(false);
    const [currentFilename, setCurrentFilename] = useState("");
    const [currentFileData, setCurrentFileData] = useState("");
    const [countsInformation, setCountsInformation] = useState({});
    const [pdfUrls, setPdfUrls] = useState({})
    const location = useLocation();
    const [requestLifeCycleThree, setRequestLifeCycleThree] = useState(false);
    const [error, setError] = useState(null);    
    const [ignoredPaths, setIgnoredPaths] = useState([]);
    const [ignoredPrefixes, setIgnoredPrefixes] = useState({});
    const [id, setId] = useState("");
    const [stillProcessing, setStillProcessing] = useState(false);
    const [status, setStatus] = useState(null);
    const [text, setText] = useState("Nothing to re-run on autograders");

    function updateText(newText) {
        setText(newText);
    }

    function updateId(id) {
        setId(id);
    }

    function updatePdfUrls(pdfUrls) {
        setPdfUrls(pdfUrls);
    }

    function updateStatus(newStatus) {
        setStatus(newStatus)
    }

    useEffect(() => {
        const fetchSubmissionsAndGradedCount = async () => {
            try {
                setRequestLifeCycleThree(true)
                const response = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-student-test-results/${courseCode}/${assessmentCode}/${email}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await response.json();
                if (response.status === 200) {
                    if (data.OK) {
                        const testsConfigArray = JSON.parse(data.testsConfig)
                        setTestsConfigDict(testsConfigArray.tests.reduce((acc, curr) => {
                            acc[curr.id] = curr;
                            return acc;
                        }, {}));
                    } else {
                        setError(data.error)
                    }
                    updateStatus(data.status);
                } else if (response.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                } else {
                    setError(data.detail)
                }
        
            } catch (error) {
                setError(error.message);
                console.log(error);
            } finally {
                setRequestLifeCycleThree(false);
            }
        }

        fetchSubmissionsAndGradedCount();
        
    }, []);

    useEffect(() => {
        const iterateAndFetchPdfs = async () => {
            // initally set requestLifeCycle
            updatePdfUrls({
                requestLifeCycle: true
            })

            // returns a dict where key is uuid of scan and grade and value is null
            const questionsDict = findUuidsOfScanAndGrade(assessmentInformation.gradedQuestions);

            // handle the requests for the pdfs
            const dataPromises = Object.keys(questionsDict).map(async uuid => {
                try {
                    const [isOkay, pdfUrl] = await fetchSubmissionPdf(process.env.REACT_APP_SUBMISSION_API_URL + `/get-question-pdf/${courseCode}/${assessmentCode}/${email}/${uuid}`);
                    if (isOkay) {
                        questionsDict[uuid] = pdfUrl;
                    }
                } catch (error) {
                    console.error(error);
                    return null; // Handle errors gracefully
                }
            });

            await Promise.all(dataPromises);
            questionsDict.requestLifeCycle = false 
            updatePdfUrls(questionsDict);
        }

        if (assessmentInformation.gradedQuestions && assessmentInformation.gradedQuestions.length > 0) {
            iterateAndFetchPdfs()
        }

        return () => {
            Object.keys(pdfUrls).map((uuid) => {
                if (uuid && pdfUrls[uuid]) {
                    URL.revokeObjectURL(pdfUrls[uuid])
                }
            })
        }

    }, [assessmentInformation.gradedQuestions])

    useEffect(() => {
        const fetchSubmissionsAndGradedCount = async () => {
            try {
                const res = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-submissions-and-graded-count/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await res.json();
                if (res.status === 200) {
                    setCountsInformation(data)
                } else if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                }
        
            } catch (error) {
                console.log(error);
            }
        }

        fetchSubmissionsAndGradedCount();
        
    }, []);

    function updateCurrentFilename(filename) {
        setCurrentFilename(filename);
    }

    function updateCurrentFileData(data) {
        setCurrentFileData(data);
    }

    function updateSetStillProcessing(newValue) {
        setStillProcessing(newValue);
    }
    
    useEffect(() => {
        const fetchTheme = async () => {
            try {
                const response = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-theme`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await response.json();
                if (response.status === 200) {
                    setIsDarkTheme(data.isDarkTheme);
                } else if (response.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                }
            } catch (error) {
                console.log(error);
            }
        };
        
        fetchTheme();
    }, [])

    function updateIsDarkTheme(theme) {
        setIsDarkTheme(theme);
        submitForm(theme);
    }

    function updateAssessmentInformation(newInfo) {
        setAssessmentInformation(newInfo);
    }

    const submitForm = async (theme) => {
        try {
            const url = process.env.REACT_APP_SUBMISSION_API_URL + "/update-theme";
            const urlOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    isDarkTheme: theme
                })
            }

            const response = await fetch(url, urlOptions);
            if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL
            }

        } catch(error) {
            console.log(error);
        }
    }

    const fetchAssessmentInformation = async () => {
        if (!email) {
            return;
        }

        try {
            setRequestLifeCycle(true);
            const res = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-submission-information/${courseCode}/${assessmentCode}/${email}`, {
                method: "GET",
                credentials: "include"
            });
            const data = await res.json();
            if (res.status === 200) {
                updateAssessmentInformation(data);
            } else if (res.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
    
        } catch (error) {
            console.log(error);
        } finally {
            setRequestLifeCycle(false);
        }
    }

    useEffect(() => {
        const fetchZip = async () => {
            try {
                setRequestLifeCycleTwo(true)
                const res = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-submission-zip/${courseCode}/${assessmentCode}/${email}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await res.json()
                if (res.status === 200) {
                    const [status, blob] = await fetchWithPreSigned(data.url); // Get the ZIP as a blob
                    
                    if (status !== 200) {
                        return;
                    }
                    setBlob(blob)
                    const contents = await parseZipFile(blob);
                    setContentsForViewer(contents);
                    const parsedContents = parseFileStructure(contents)
                    setContentsForCustomExam(parsedContents)

                    // get ignore information
                    const testsConfig = "tests_config.json" in parsedContents ? JSON.parse(parsedContents["tests_config.json"].content) : {}
                    const ignoredPaths = testsConfig?.ignore.reduce((acc, path) => {
                        acc[path] = null;
                        return acc;
                    }, {})

                    setIgnoredPaths(ignoredPaths)
                    setIgnoredPrefixes(retIgnoredPrefixes(ignoredPaths))
                } else if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                }
            } catch (error) {
                console.log(error);
            } finally {
                setRequestLifeCycleTwo(false);
            }
        };
        
        if (assessmentInformation.zipFileExists && email) {
            fetchZip();
        }

    }, [assessmentInformation.zipFileExists, email])

    useEffect(() => {
        fetchAssessmentInformation();
    }, [])

    const handleDetailsClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/settings`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/settings`);
        }
    }

    const handleStatisticsClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/settings`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/statistics`);
        }
    }

    const handleDashboardClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/settings`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/`);
        }
    }

    function updateDeleteEvaluation(deleteVal) {
        setDeleteEvaluation(deleteVal);
    }

    const handleFocus = () => {
        setIsCursorInside(true);
    };
    
    const handleBlur = () => {
        setIsCursorInside(false);
    };

    function updateCurrentViewer(newViewer) {
        setCurrentViewer(newViewer);
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tab = queryParams.get("tab")
        const id = queryParams.get("id")

        if (tab) {
            setCurrentViewer(tab)
        } else {        
            setCurrentViewer(TypesOfLocationEnum.SETTINGS)
        }

        if (id) {
            updateId(id)
        }

    }, [])

    useEffect(() => {
        // Existing query parameters
        const searchParams = new URLSearchParams(location.search);

        // Add or modify a query parameter
        searchParams.set('id', id); // Adds or updates 'newParam'

        // Build the new query string
        const newSearch = searchParams.toString();

        // Navigate to the same path with the updated query params
        navigate({
            pathname: location.pathname,
            search: newSearch,
        });
        
    }, [id])

    function currentViewerToReturn() {
        switch(currentViewer) {
            case TypesOfLocationEnum.ZIP: {
                return <ZipFileViewerGradingPage currentFilename={currentFilename} currentFileData={currentFileData} updateCurrentFilename={updateCurrentFilename} updateCurrentFileData={updateCurrentFileData} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} requestLifeCycle={requestLifeCycleTwo} blob={blob} contents={contentsForViewer}/>
            }
            case TypesOfLocationEnum.CUSTOM_EXAM: {
                return <GradingPageCustomExamWrapper status={status} endedExam={assessmentInformation.endedExam} error={error} testsConfigDict={testsConfigDict} id={id} updateId={updateId} ignoredPaths={ignoredPaths} ignoredPrefixes={ignoredPrefixes} requestLifeCycleTwo={requestLifeCycleTwo} requestLifeCycle={requestLifeCycle} updatePdfUrls={updatePdfUrls} pdfUrls={pdfUrls} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} updateCurrentViewer={updateCurrentViewer} contents={contentsForCustomExam} gradedQuestions={assessmentInformation.gradedQuestions}/>
            }
            default: {
                return <></>
            }
        }
    }

    const findIfLambdaExists = () => {
        return assessmentInformation?.gradedQuestions?.filter((question) => (question?.questionType === TypesOfQuestionsEnum.LAMBDA && Object.keys(question?.testsMap).length > 0 && question.language !== "plaintext"))?.length > 0
    }

    useEffect(() => {
        
        setStillProcessing(findStillProcessing(assessmentInformation.gradedQuestions, status));

    }, [status, assessmentInformation.gradedQuestions])

    const findMessage = () => {
        const atLeastOneLambda = findIfLambdaExists();

        if (assessmentInformation.zipFileExists && atLeastOneLambda) {
            updateText("Re-run programming and lambda responses");
        } else if (assessmentInformation.zipFileExists) {
            updateText("Re-run programming responses");
        } else if (atLeastOneLambda) {
            updateText("Re-run lambda responses");
        } else {
            updateText("Nothing to re-run on autograders");
        }
    }

    useEffect(() => {
        let timer = null;

        if (text === "Success" || text === "Failed") {
            timer = setTimeout(() => {
                findMessage();
            }, 3000);
        } else {
            findMessage();
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        }

    }, [assessmentInformation, assessmentInformation.gradedQuestions, assessmentInformation.zipFileExists])

    return (
        <>
            <Navigation currentViewer={currentViewer} id={id} isCursorInside={isCursorInside} boolFive={true} bool={false} info={ <NavInfoSubmissions isProgramming={assessmentInformation.isProgramming} updateDeleteEvaluation={updateDeleteEvaluation} handleDashboardClick={handleDashboardClick} handleStatisticsClick={handleStatisticsClick} handleDetailsClick={handleDetailsClick} name={assessmentInformation.evalName} description={"assessment settings. You may change assessment settings up to 30 minutes before the scheduled start time."} title={"Manage Settings"}/> } infoCollapsed={ <NavInfoSubmissionsCollapsed handleDashboardClick={handleDashboardClick} updateDeleteEvaluation={updateDeleteEvaluation} handleStatisticsClick={handleStatisticsClick} handleDetailsClick={handleDetailsClick}/>}/>
            <div className="courses-container" style={{ position: width ? "relative" : "fixed" }}>
                <div className={`single-course-container viewer full ${width ? "small" : "height"}`}>
                    <div className={`assessment-page-content min ${width ? "small" : ""}`}>
                        {
                            currentViewer === TypesOfLocationEnum.SETTINGS

                            ?                    

                            <>
                                <LeftInfoGradingPage findIfLambdaExists={findIfLambdaExists} text={text} updateText={updateText} updateAssessmentInformation={updateAssessmentInformation} updateStatus={updateStatus} updateSetStillProcessing={updateSetStillProcessing} status={status} stillProcessing={stillProcessing} endedExam={assessmentInformation.endedExam} error={error} testsConfigDict={testsConfigDict} id={id} updateId={updateId} ignoredPaths={ignoredPaths} ignoredPrefixes={ignoredPrefixes} requestLifeCycleTwo={requestLifeCycleTwo || requestLifeCycleThree} updatePdfUrls={updatePdfUrls} pdfUrls={pdfUrls} countsInformation={countsInformation} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} contents={contentsForCustomExam} updateCurrentViewer={updateCurrentViewer} requestLifeCycle={requestLifeCycle} assessmentInformation={assessmentInformation} isCursorInside={isCursorInside} handleBlur={handleBlur} handleFocus={handleFocus}/>
                            </>

                            :
                            
                            <>
                            </>

                        }
                        {
                            currentViewerToReturn()
                        }
                    </div>
                </div>
            </div>
            <LocationSettingsHover grading={true} pdfExists={false} zipFileExists={assessmentInformation.zipFileExists} updateCurrentViewer={updateCurrentViewer} isProgramming={assessmentInformation.isProgramming}/>
            { deleteEvaluation ? <DeleteEvaluationForm name={assessmentInformation.name} updateDeleteEvaluation={updateDeleteEvaluation} /> : <></> }
        </>
    );
}

export default GradingPage;