import React from "react";

const NotFoundSingleQ = ({ id, updateId }) => {
    return (
        <>
            <div className="not-found-sq">
                Question not found. Back to all questions:
                <input type={"checkbox"} className="check-auto" checked={id !== ""} onChange={() => updateId("")} style={{ cursor: "pointer" }}/>
            </div>
        </>
    );
}

export default NotFoundSingleQ