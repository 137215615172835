import React from "react";
import TypesOfLocationEnum from "./typesOfLocationEnum";
import ASingleTest from "./aSingleTest";

const TestsDropdown = ({ questions, testsConfigDict, updateCurrentViewer }) => {
    return (
        <>
            {
                questions && questions.length > 0

                ?

                <>
                    <div className="question-tests-container">
                        {
                            questions.map((question) => (
                                question.tests.map((id) => {
                                    if (id in testsConfigDict) {
                                        return <ASingleTest key={id} {...testsConfigDict[id]} index={question.index}/>
                                    }
                                })
                            ))
                        }
                    </div>
                </>

                :

                <>
                    <div className="no-questions">
                        <div className="no-assignments-container" style={{ paddingTop: "0px"}}>
                            <div className="no-assignments"> 
                                There are currently no autograde tests for any questions
                                <div className="save-changes-2" onClick={() => {
                                    updateCurrentViewer(TypesOfLocationEnum.CUSTOM_EXAM);
                                }}>
                                    Back To Questions
                                </div>
                            </div>
                        </div>
                    </div>
                </>

            }
        </>
    );
}

export default TestsDropdown;