import React from "react";
import TypesOfLocationEnum from "./typesOfLocationEnum";
import { useNavigate, useLocation } from "react-router-dom";

const LocationSettingsItem = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const setUrl = () => {
        if (props.path !== TypesOfLocationEnum.PDF) {
            // Existing query parameters
            const searchParams = new URLSearchParams(location.search);

            // Add or modify a query parameter
            searchParams.set('tab', props.path); // Adds or updates 'newParam'

            // Build the new query string
            const newSearch = searchParams.toString();

            // Navigate to the same path with the updated query params
            navigate({
                pathname: location.pathname,
                search: newSearch,
            });
        }
    }

    return (
        <>
            <div className="location-settings-hover-item" onClick={() => {
                setUrl()              
                props.updateCurrentViewer(props.path)
            }}>
                { props.svgOne }
            </div>
        </>
    );
}

export default LocationSettingsItem;