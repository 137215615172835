import React, { useEffect } from "react";
import useWindowWidth from "./useWindowWidth";

const NextPrevious = (props) => {
    const width = useWindowWidth(780);

    const handleKeyDown = (event) => {
        if (props.isCursorInside) {
            return;
        }
        if (event.key === props.direction) {
            props.handleClick(props.type, props.id, props.tab);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, [props.isCursorInside, props.id, props.tab]);

    return (
        <>
            <div
                className={props.inverted ? "next-previous" : "ungraded-next-previous"}
                onClick={() => { props.handleClick(props.type, props.id, props.tab); }}
                style={{ width: width ? props.width : "" }}
            >
                {props.leftToRight ? (
                    <>
                        {props.svg}
                        {width ? "" : props.text}
                    </>
                ) : (
                    <>
                        {width ? "" : props.text}
                        {props.svg}
                    </>
                )}
            </div>
        </>
    );
}

export default NextPrevious;
