import { languageReadableMapLambda } from "./languageReadableMapLambda";

const findPlaceholderTest = (language, index) => {

    switch (language) {
        case languageReadableMapLambda["Python 3.12"]: {
            return "my_class.method_" + index.toString() + "(<arguments>) <operator> <expected>"
        }
        case languageReadableMapLambda["Java 21"]: {
            return "myClass.method" + index.toString() + "(<arguments>) <operator> <expected>"
        }
        case languageReadableMapLambda["Node.js 22"]: {
            return "myClass.method" + index.toString() + "(<arguments>) <operator> <expected>"
        }
        default: {
            return ""
        }
    }
}

export default findPlaceholderTest;