import React, { useState } from "react";
import Graph from "./graph";
import Download from "./download";
import Server from "./server";
import Settings from "./settings";
import Loading from "./loading";
import TrashCan from "./trashCan";
import Document from "./document";
import ZipFile from "./zipFile";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Clipboard2 from "./clipboard2";

const NavInfoSubmissions = (props) => {
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [requestLifeCycleTwo, setRequestLifeCycleTwo] = useState(false);
    const { courseCode, assessmentCode } = useParams()
    const location = useLocation();
    const navigate = useNavigate();
    const currentPath = location.pathname;

    const handleGetSubmissionInformation = async () => {
        const url = process.env.REACT_APP_SUBMISSION_API_URL + `/get-assessment-grades/${courseCode}/${assessmentCode}`;
    
        try {
            setRequestLifeCycle(true)
            const response = await fetch(url, {
                method: "GET",
                credentials: "include"
            });
            
            if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
    
            const blob = await response.blob(); // Convert response to a Blob
            const urlBlob = URL.createObjectURL(blob); // Create a URL for the Blob
    
            // Create a link element
            const a = document.createElement('a');
            a.href = urlBlob;
            a.download = props.name.toLowerCase().replace(/ /g, '-') + '-grades.csv'; // Name of the downloaded file
    
            // Append the link to the body
            document.body.appendChild(a);
            a.click(); // Programmatically click the link to trigger the download
    
            // Clean up
            document.body.removeChild(a);
            URL.revokeObjectURL(urlBlob); // Revoke the Blob URL
            setRequestLifeCycle(false)
        } catch (error) {
            console.error('Failed to fetch the CSV file:', error);
        } finally {
            setRequestLifeCycle(false)
        }
    }

    const handleGetSubmissionZips = async () => {
        const url = process.env.REACT_APP_SUBMISSION_API_URL + `/get-submission-zips-zip/${courseCode}/${assessmentCode}`;
    
        try {
            setRequestLifeCycleTwo(true)
            const response = await fetch(url, {
                method: "GET",
                credentials: "include"
            });
            
            if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
    
            // Create a Blob from the response
            const blob = await response.blob();
    
            // Create a URL for the Blob
            const downloadUrl = window.URL.createObjectURL(blob);
    
            // Create a link element
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = props.name.toLowerCase().replace(/ /g, '-') + '-submissions.zip'; // Name of the downloaded file
    
            // Append the link to the body
            document.body.appendChild(a);
    
            // Programmatically click the link to trigger the download
            a.click();
    
            // Clean up and remove the link
            document.body.removeChild(a);
            window.URL.revokeObjectURL(downloadUrl);
    
        } catch (error) {
            console.log('Error fetching ZIP file:', error);
        } finally {
            setRequestLifeCycleTwo(false)
        }
    };

    const handleDocumentClick = async (event) => {
        if (!currentPath.includes("@")) {
            try {
                const url = process.env.REACT_APP_SUBMISSION_API_URL + `/get-next-submission/${courseCode}/${assessmentCode}/na`;
                const urlOptions = {
                    method: "GET",
                    credentials: "include"
                }
                
                const response = await fetch(url, urlOptions);
                const data = await response.json();
                if (response.status === 200) {
                    if (event.metaKey || event.ctrlKey) {
                        // Open in a new tab
                        window.open(`/${courseCode}/${assessmentCode}/${data.email}`, '_blank');
                    } else {
                        // Navigate in the same tab
                        navigate(`/${courseCode}/${assessmentCode}/${data.email}`);
                    }
                } else if (response.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL
                }
    
    
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleEditClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${courseCode}/${assessmentCode}/edit`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${courseCode}/${assessmentCode}/edit`)
        }
    }

    return (
        <>
            <div className="semester-nav-info">
                <div className="side-nav-info-header">
                    { props.name }
                </div>
                <div className={`open-nav ${!currentPath.endsWith("/statistics") && !currentPath.endsWith("/settings") && !currentPath.includes("@") && !currentPath.endsWith("/edit") ? "current" : "hover"}`} style={{ cursor: "pointer" }} onClick={ props.handleDashboardClick }>
                    <Server /> Dashboard
                </div>
                <div className={`open-nav ${currentPath.endsWith("/edit") ? "current" : "hover"}`} style={{ cursor: "pointer" }} onClick={ handleEditClick }>
                    <Clipboard2 /> Edit Eval
                </div>
                <div className={`open-nav ${currentPath.endsWith("/settings") ? "current" : "hover"}`} style={{ cursor: "pointer" }} onClick={ props.handleDetailsClick }>
                    <Settings /> Settings
                </div>
                <div className={`open-nav ${currentPath.includes("@") ? "current" : "hover"}`} style={{ cursor: "pointer" }} onClick={ handleDocumentClick }>
                    <Document /> Grade Submission
                </div>
                <div className={`open-nav ${currentPath.endsWith("/statistics") ? "current" : "hover"}`} style={{ cursor: "pointer" }} onClick={ props.handleStatisticsClick }>
                    <Graph /> Statistics
                </div>
                <div className="side-nav-info-header">
                    Actions
                </div>
                {
                    false && (
                        <>
                            <div className="instructor-name underline" style={{ cursor: "pointer", width: "100%" }} onClick={handleGetSubmissionZips}>
                                <ZipFile /> 
                                { 
                                    requestLifeCycleTwo

                                    ?

                                    <div className="loading-container" style={{ maxWidth: "120px" }}>
                                        <Loading />
                                    </div>

                                    :

                                    <div className="loading-container" style={{ justifyContent: "flex-start" }}>
                                        Download Zip Files
                                    </div>

                                }
                            </div>
                        </>
                    )
                }                
                <div className="instructor-name underline" style={{ cursor: "pointer", width: "100%" }} onClick={handleGetSubmissionInformation}>
                    <Download /> 
                    { 
                        requestLifeCycle

                        ?

                        <div className="loading-container" style={{ maxWidth: "110px" }}>
                            <Loading />
                        </div>

                        :

                        <div className="loading-container" style={{ justifyContent: "flex-start" }}>
                            Download Grades
                        </div>

                    }
                </div>
                <div className="instructor-name underline" style={{ cursor: "pointer" }} onClick={ () => { props.updateDeleteEvaluation(true) } }>
                    <TrashCan /> Delete Evaluation
                </div>
            </div>
        </>
    );
}

export default NavInfoSubmissions;