import React from "react";

const AddTestsForm = ({ tests, testsConfigDict, handleTestDeletion, updateTests, updateSelectTests }) => {
    const handleClick = (checked, id) => {
        if (checked) {
            updateTests(id)
        } else {
            handleTestDeletion(id)
        }
    }

    return (
        <>
            <div className="black-back" onClick={ () => {updateSelectTests(false)} } style={{ opacity: 0 }}>

            </div>
            <div className="add-a-course-form-container add-a-question">
                <div className="add-a-course-form-content-container">
                    <form className="form-width">
                        <div className="add-course-title">
                            Select Autograde Tests
                        </div>
                        <div className="account-settings-content" style={{ marginTop: "10px", rowGap: "10px" }}>
                            {
                                Object.keys(testsConfigDict).map((testId) => (
                                    <label className="label-test" key={testId}>
                                        <input className="check-auto" style={{ cursor: "pointer" }} type="checkbox" checked={tests.includes(testId)} onChange={(e) => handleClick(e.target.checked, testId)}/>
                                        <div className="test-label-ind">
                                            {`Name: ${testsConfigDict[testId].name} | Total Points: ${Number(testsConfigDict[testId].totalPoints).toFixed(2)} | ID: ${testsConfigDict[testId].id}`}  
                                        </div>                                         
                                    </label>
                                ))
                            }
                            <div className="save-changes clear" onClick={(e) => handleClick(true, null)}>
                                Clear
                            </div>
                        </div>
                    </form> 
                </div>
                <div className="x" onClick={() => updateSelectTests(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 16 16">
                        <path fill="rgb(60,60,60)" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                    </svg>
                </div>
            </div>
        </>
    );
}

export default AddTestsForm;