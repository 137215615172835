import React, { useState } from "react";
import KeybindHints from "./keybindHints";
import useWindowWidth from "./useWindowWidth";

const OnlyIInfo = ({ currentViewer }) => {
    const [isHovered, setIsHovered] = useState(false);
    const width = useWindowWidth(1000);

    return (
        <>
            {
                width

                ?

                <>
                </>

                :

                <div className="info-hover" style={{ position: "fixed", left: "80px" }}>
                    <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" viewBox="0 0 16 16">
                            <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                        </svg>
                    </div>
                    {
                        isHovered

                        ?

                        <KeybindHints currentViewer={currentViewer}/>

                        :

                        <>
                        </>
                        
                    }
                </div>

            }            
        </>
    );
}

export default OnlyIInfo;
