import React, { useState, useEffect } from "react";
import Navigation from "./navigation";
import NavInfoAccount from "./navInfoAccount";
import PersonWorkspace from "./personWorkspace";
import AccountInput from "./accountInput";
import SaveChanges from "./saveChanges";
import useScrollToTop from "./useScrollToTop";
import AccountInstructor from "./accountInstructor";
import EmailAddress from "./emailAddress";
import Support from "./support";
import { useNavigate } from "react-router-dom";

const AccountSettings = () => {
    const navigate = useNavigate();
    useScrollToTop();
    const [text, setText] = useState("Save Changes");
    const [account, setAccount] = useState({
        name: "",
        primaryEmail: "",
    });

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                const res = await fetch( process.env.REACT_APP_USER_API_URL + `/get-account-information`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await res.json();
                if (res.status === 401) {
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                }
                setAccount(data);
                updateName(data.name)
        
            } catch (error) {
                console.log(error);
            }
        }

        fetchCourse();
    }, [])

    const [form, setForm] = useState({
        name: account.name,
        studentId: "NA",
        newPassword: "",
        newPasswordConfirmation: "",
    });

    function updateName(name) {
        setForm(prevForm => ({
            ...prevForm,
            name: name
        }))
    }

    function updateNewPassword(newPassword) {
        setForm(prevForm => ({
            ...prevForm,
            newPassword: newPassword
        }))
    }

    function updateNewPasswordCofirmation(newPasswordConfirmation) {
        setForm(prevForm => ({
            ...prevForm,
            newPasswordConfirmation: newPasswordConfirmation
        }))
    }

    const handleLogout = async () => {
        try {
            const res = await fetch( process.env.REACT_APP_USER_API_URL + "/logout", {
                method: "POST",
                credentials: "include"
            })
            if (res.status === 401 || res.status === 200) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL.replace(/\/login$/, '');
            }
        } catch (error) {
            console.log(error)
        }
    }

    const resetForm = (e) => {
        e.preventDefault();
        setForm({
            name: account.name,
            studentId: "NA",
            newPassword: "",
            newPasswordConfirmation: "",
        })
    }

    const submitForm = async (e) => {
        e.preventDefault();
        
        for (const value in form) {
            if (form[value] === "" && !value.includes("Password")) {
                setText("Blank fields")
                return;
            }
        }

        if (form.name.length > 100) {
            setText("Name too long")
            return;
        }

        if (form.newPassword.length > 64) {
            setText("Password too long")
            return;
        }

        if (form.name.trim() === "") {
            setText("Remove whitespace");
            return;
        }

        if ((form.newPassword.length < 12) && form.newPassword !== "") {
            setText("Password length");
            return;
        }

        if (((form.newPassword !== form.newPasswordConfirmation)) && form.newPassword !== "") {
            setText("Passwords differ");
            return;
        }

        const url =  process.env.REACT_APP_USER_API_URL + "/update-account-info";

        const urlOptions = {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(form)
        }

        try {
            const res = await fetch(url, urlOptions);
            if  (res.status === 200) {
                window.location.reload();
            } else if (res.status === 415) {
                setText("Invalid characters")
            } else if (res.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
    
        } catch (error) {
            console.log(error);
        }        
    }

    const pageInfo = [
        {
            top : "Password (12+ Characters)",
            value : form.newPassword,
            updateValue : updateNewPassword,
            autoComplete : "new-password",
            required : false,
        },
        {
            top : "Password Confirmation",
            value : form.newPasswordConfirmation,
            updateValue : updateNewPasswordCofirmation,
            autoComplete : "new-password",
            required : false,
        },
    ]

    const handleClick = (event, path) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${path}`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${path}`);
        }
    }

    return (
        <>
            <Navigation bool={false} info={ <NavInfoAccount /> }/>
            <div className="courses-container">
                <div className="header">
                    <form className="courses-container-2">
                        <div className="account-header-container">
                            <div className="your-courses">
                                Your Account
                            </div>
                            <div className="person-edit-container">
                                <PersonWorkspace /> { account.name }
                            </div>
                        </div>
                        <div className="account-settings-content">
                            <div className="required-container">
                                <div className="required">
                                    *
                                </div> Required Field
                            </div>
                            <AccountInput top={"Full Name"} required={true} value={form.name} updateValue={updateName} autoComplete={"name"}/>
                            <div className="input-field padding auto">
                                <div className="top-input bottom">
                                    Email Address
                                </div>
                                <EmailAddress email={account.primaryEmail} primary={false}/>
                            </div>
                            {
                                pageInfo.map((item) => {
                                    return <AccountInput key={item.top} top={item.top} required={item.required} value={item.value} updateValue={item.updateValue} autoComplete={item.autoComplete}/>
                                })
                            }
                            <SaveChanges left="Cancel" right={{ small : text, large : text }} resetForm={ resetForm } submitForm={submitForm}/>
                            <div className="logout" onClick={ handleLogout }>
                                <AccountInstructor /> Log out of All Devices
                            </div>
                            <Support />
                            <div className="pp-and-tac">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--accent-one)" viewBox="0 0 16 16">
                                    <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M5 4h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1m-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1m0 2h3a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1"/>
                                </svg>
                                <span className="item-of-tac" onClick={(e) => handleClick(e, "privacy-policy")}>Privacy Policy</span> 
                                <span style={{ color: "var(--almost-black)", textDecoration: "none" }}>and</span> 
                                <span className="item-of-tac" onClick={(e) => handleClick(e, "terms-and-conditions")}>Terms and Conditions</span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default AccountSettings;