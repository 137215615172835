import React, { useState } from "react";
import Dashboard from "./dashboard";
import Navigation from "./navigation";
import useScrollToTop from "./useScrollToTop";
import NavInfoSemester from "./navInfoSemester";
import NavInfoSemesterCollapsed from "./navInfoSemesterCollapsed";

const SemesterDashboard = () => {
    useScrollToTop();
    const [color, setColor] = useState("#0B73FE");
    const [isHolding, setIsHolding] = useState(false);

    function updateColor(color) {
        setColor(color);
    }

    function updateIsHolding(isHolding) {
        setIsHolding(isHolding)
    }
    
    return (
        <>
            <Navigation isHolding={isHolding} boolThree={false} boolTwo={false} bool={true} info={<NavInfoSemester updateColor={updateColor} color={color} updateIsHolding={updateIsHolding}/>} infoCollapsed={<NavInfoSemesterCollapsed updateColor={updateColor} color={color}/>}/>
            <Dashboard />
        </>
    );
}

export default SemesterDashboard;