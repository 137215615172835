export const languageMap = {
    'abap': 'abap',
    'apex': 'apex',
    'azcli': 'azcli',
    'bat': 'bat',
    'bicep': 'bicep',
    'c': 'c',
    'cameligo': 'cameligo',
    'clj': 'clojure',
    'coffee': 'coffeescript',
    'cpp': 'cpp',
    'cs': 'csharp',
    'css': 'css',
    'dart': 'dart',
    'dockerfile': 'dockerfile',
    'ecl': 'ecl',
    'ex': 'elixir',
    'fs': 'fsharp',
    'go': 'go',
    'graphql': 'graphql',
    'hcl': 'hcl',
    'html': 'html',
    'ini': 'ini',
    'java': 'java',
    'js': 'javascript',
    'jl': 'julia',
    'kt': 'kotlin',
    'less': 'less',
    'lexon': 'lexon',
    'lua': 'lua',
    'md': 'markdown',
    'm3': 'm3',
    'mysql': 'mysql',
    'obj': 'objective-c',
    'pas': 'pascal',
    'pascaligo': 'pascaligo',
    'pl': 'perl',
    'pgsql': 'pgsql',
    'php': 'php',
    'plaintext': 'plaintext',
    'powerquery': 'powerquery',
    'powershell': 'powershell',
    'proto': 'proto',
    'pug': 'pug',
    'py': 'python',
    'qs': 'qsharp',
    'r': 'r',
    'razor': 'razor',
    'redis': 'redis',
    'redshift': 'redshift',
    'rst': 'restructuredtext',
    'rb': 'ruby',
    'rs': 'rust',
    'scala': 'scala',
    'scm': 'scheme',
    'scss': 'scss',
    'sh': 'shell',
    'sparql': 'sparql',
    'sql': 'sql',
    'st': 'st',
    'swift': 'swift',
    'systemverilog': 'systemverilog',
    'tcl': 'tcl',
    'twig': 'twig',
    'ts': 'typescript',
    'vb': 'vb',
    'v': 'verilog',
    'xml': 'xml',
    'yml': 'yaml',  // this is the extra one
    'yaml': 'yaml', 
    'json': 'json'
  };  