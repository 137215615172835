import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const GradingBoxQuestion = ({ pointsEarned, uuid, updateUseCustomScoreFromGrading }) => {
    const [pointsEarnedInit, setPointsEarnedInit] = useState(Number(pointsEarned).toFixed(2));
    const [pointsEarnedDynamic, setPointsEarnedDynamic] = useState(Number(pointsEarned).toFixed(2));
    const { courseCode, assessmentCode, email } = useParams();

    const handleChange = (e) => {
        setPointsEarnedDynamic(e.target.value);
    }

    const submitForm = async () => {
        if (Number(pointsEarnedDynamic).toFixed(2) === Number(pointsEarnedInit).toFixed(2)) {
            return;
        }
        try {
            const url = process.env.REACT_APP_SUBMISSION_API_URL + "/update-points-earned";

            const urlOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify({
                    courseCode: courseCode,
                    assessmentCode: assessmentCode,
                    email: email,
                    pointsEarned: pointsEarnedDynamic,
                    uuid: uuid
                })
            }

            const response = await fetch(url, urlOptions);
            if (response.status === 200) {
                setPointsEarnedInit(pointsEarnedDynamic)
                updateUseCustomScoreFromGrading(true)
            } else if (response.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (pointsEarnedDynamic !== pointsEarnedInit) {
                submitForm();
            }
        }, 500)

        return () => {
            clearTimeout(timer);
        };

    }, [pointsEarnedDynamic])

    return (
        <>
            <input onBlur={submitForm} className="grading-box-question" type="text" value={pointsEarnedDynamic} onChange={handleChange}/>
        </>
    );
}

export default GradingBoxQuestion;