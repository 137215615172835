import React, { useState } from "react";
import CourseInput from "./courseInput";
import SaveChanges from "./saveChanges";
import { useParams, useNavigate } from "react-router-dom";
import WhiteLoading from "./whiteLoading";
import Info from "./info";

const DeleteCourseForm = (props) => {
    const [text, setText] = useState("Type exit message to delete evaluation");
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const { courseCode } = useParams();
    const navigate = useNavigate();
    const [remove, setRemove] = useState("");

    function updateRemove(remove) {
        setRemove(remove)
    }

    const resetForm = () => {
        props.updateDeleteCourse(false);
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if (requestLifeCycle) {
            return;
        }

        if (remove === "Delete " + props.name) {
            const url = process.env.REACT_APP_COURSE_API_URL + `/delete-course`

            const urlOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ courseCode: courseCode })
            }

            try {
                setRequestLifeCycle(true)
                const res = await fetch(url, urlOptions);
                if (res.status === 200) {
                    setText("Course Deleted")
                    navigate(`/`)
                    props.updateDeleteCourse(false);
                } else if (res.status === 408) {
                    setText("There is more than one instructor")
                } else if (res.status === 409) {
                    setText("Student roster is not empty")
                } else if (res.status === 403) {
                    setText("Please delete all evaluations before course deletion")
                } else if (res.status === 404) {
                    setText("Your account or course was not found")
                }
                else if (res.status === 401) {
                    setText("Authentication error")
                    window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
                } else {
                    setText("An error occurred")
                }
    
            } catch (error) {
                setText("An error occurred")
                console.log(error);
            } finally {
                setRequestLifeCycle(false)
            }
        }
    }

    return (
        <>
            <div className="black-back" onClick={ () => {props.updateDeleteCourse(false)} }>

            </div>
            <div className="add-a-course-form-container">
                <div className="add-a-course-form-content-container">
                    <form className="form-width">
                        <div className="add-course-title">
                            Delete Course
                        </div>
                        <div className="account-settings-content">
                            <div className="required-container">
                                All Fields Required
                            </div>   
                            <div className="banner">
                                {
                                    requestLifeCycle 

                                    ?
                                    
                                    <>
                                        <WhiteLoading />
                                    </>

                                    :

                                    <div className="banner-info">
                                        <Info />
                                        { text }
                                    </div>

                                }
                            </div>
                            <CourseInput value={remove} updateValue={updateRemove} top={"Type: Delete " + props.name} type={"text"} required={true} min={0} placeholder={"Delete " + props.name}/>
                            <SaveChanges left="Cancel" right={{ small : "Delete", large : "Delete" }} resetForm={resetForm} submitForm={submitForm}/> 
                        </div>
                    </form> 
                </div>
            </div>
        </>
    );
}

export default DeleteCourseForm;