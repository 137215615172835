import React from "react";
import CustomExamWrapperView from "./customExamWrapperView";
import CustomExamWrapperEdit from "./customExamWrapperEdit";
import AddAQuestionForm from "./addAQuestionForm";
import WhiteLoading from "./whiteLoading";
import { MathJaxContext } from "better-react-mathjax";
import mathJaxConfig from "./mathJaxConfig";

const CustomProgrammingExam = ({ ignoredPrefixes, requestLifeCycleTwo, updateContents, updateIsAllowedToScoll, isAllowedToScroll, isDarkTheme, updateIsDarkTheme, contents, testsConfigDict, ignoredPaths, requestLifeCycle, questions, questionsToSave, updateQuestionsToSave, isEditing, addAQuestion, updateIsEditing, updateAddAQuestion, updateDeleteQuestion, useSyntaxHighlighting, zipFileExists, updateQuestionsParent }) => {
    function updateQuestions(questions) {
        updateQuestionsParent(questions);
    }

    return (
        <>
            {
                requestLifeCycle

                ?

                <div className="loading-zip-container">
                    <WhiteLoading />
                </div>

                :

                    <div className="custom-programming-exam-wrapper" style={{ position: "relative" }}>
                        {
                            isEditing
        
                            ?
        
                            <>
                                <CustomExamWrapperEdit isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} ignoredPrefixes={ignoredPrefixes} updateIsAllowedToScoll={updateIsAllowedToScoll} isAllowedToScroll={isAllowedToScroll} questionsToSave={questionsToSave} ignoredPaths={ignoredPaths} testsConfigDict={testsConfigDict} updateQuestionsToSave={updateQuestionsToSave} contents={contents} updateQuestions={updateQuestions} zipFileExists={zipFileExists} useSyntaxHighlighting={useSyntaxHighlighting} questions={questions} updateIsEditing={updateIsEditing} updateAddAQuestion={updateAddAQuestion} updateDeleteQuestion={updateDeleteQuestion}/>
                            </>
        
                            :
        
                            <>
                                <MathJaxContext config={mathJaxConfig}>
                                    <CustomExamWrapperView ignoredPaths={ignoredPaths} ignoredPrefixes={ignoredPrefixes} requestLifeCycleTwo={requestLifeCycleTwo} updateContents={updateContents} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} questionsToSave={questionsToSave} useSyntaxHighlighting={useSyntaxHighlighting} contents={contents} questions={questions} updateIsEditing={updateIsEditing} updateAddAQuestion={updateAddAQuestion} grading={false}/>
                                </MathJaxContext>
                            </>
                        }
                    </div>                         

            }
            { addAQuestion ? <AddAQuestionForm updateIsAllowedToScoll={updateIsAllowedToScoll} updateQuestions={updateQuestions} updateQuestionsToSave={updateQuestionsToSave} updateAddAQuestion={updateAddAQuestion} questions={questions}/> : <></> }
        </>
    );
}

export default CustomProgrammingExam;