import React, { useState } from "react";
import GradingBoxQuestion from "./gradingBoxQuestion";
import MarkdownWithLaTeX from "./markDownWithLatex";
import Regrade from "./regrade";

const TextResponseView = ({ id, updateId, isDarkTheme, useCustomScore, answerString, questionNumber, question, points, grading, pointsEarned, uuid, studentAnswer }) => {
    const [answer, setAnswer] = useState(studentAnswer);
    const [useCustomScoreFromGrading, setUseCustomScoreFromGrading] = useState(useCustomScore);

    function updateUseCustomScoreFromGrading(bool) {
        setUseCustomScoreFromGrading(bool);
    }

    function updateAnswer(e) {
        setAnswer(e.target.value);
    }

    return (
        <>
            <div className="true-false-question-view" id={uuid + "-preview"}>
                <div className="question-number">
                    <div className="grid-number-container">
                        Q{ questionNumber }
                        {
                            grading && <input type={"checkbox"} className="check-auto" checked={uuid === id} onChange={() => {updateId(uuid === id ? "" : uuid)}} style={{ cursor: "pointer" }}/>
                        }
                    </div>
                    <div className="pts">
                        {
                            useCustomScoreFromGrading && <Regrade updateUseCustomScoreFromGrading={updateUseCustomScoreFromGrading} uuid={uuid}/>
                        }
                        {
                            grading

                            ?

                            <>
                                <GradingBoxQuestion pointsEarned={pointsEarned} uuid={uuid} updateUseCustomScoreFromGrading={updateUseCustomScoreFromGrading}/>
                            </>

                            :

                            <>
                            </>
                        }
                        { (grading ? "/ " : "") + Number(points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={question} isDarkTheme={isDarkTheme}/>
                </div>
                <div className="text-response-area">
                    <textarea className="text-response-area-input" value={answer} onChange={updateAnswer} style={{ minHeight: grading ? "10px" : "" }} disabled={grading}/>
                </div>
                {
                    grading && answerString && (
                        <div className="text-response-area">
                            <div className="answer-string">
                                Correct: {answerString}
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    );
}

export default TextResponseView;