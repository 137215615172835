import React from "react";
import MarkdownWithLaTeX from "./markDownWithLatex";

const ContextBlockView = ({ isDarkTheme, questionNumber, question, points, uuid }) => {

    return (
        <>
            <div className="true-false-question-view" id={uuid + "-preview"}>
                <div className="question-number">
                    <>
                        C{ questionNumber }
                    </>
                    <div className="pts">
                        { Number(points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={question} isDarkTheme={isDarkTheme}/>
                </div>
            </div>
        </>
    );
}

export default ContextBlockView;