import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { MathJax } from "better-react-mathjax";
import { LightAsync as SyntaxHighlighter } from "react-syntax-highlighter";
import { vs, vs2015 } from "react-syntax-highlighter/dist/esm/styles/hljs";

const MarkdownWithLaTeX = ({ content, isDarkTheme }) => {
    const [containsLaTeX, setContainsLaTeX] = useState(false);

    useEffect(() => {
        if (content) {
            setContainsLaTeX(/\$[^$]+\$|\$\$[^$]+\$\$/.test(content));
        }
    }, [content]);

    const renderers = {
        code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || "");
            return !inline && match ? (
                <SyntaxHighlighter customStyle={{ fontSize: "14px", borderRadius: "5px", padding: "10px" }} style={isDarkTheme ? vs2015 : vs} language={match[1]} PreTag="div" {...props}>
                    {String(children).replace(/\n$/, "")}
                </SyntaxHighlighter>
            ) : (
                <code className={className} {...props}>
                    {children}
                </code>
            );
        },
    };

    return (
        <>
            {containsLaTeX ? (
                <MathJax dynamic hideUntilTypeset="every">
                    <ReactMarkdown components={renderers}>{content}</ReactMarkdown>
                </MathJax>
            ) : (
                <ReactMarkdown components={renderers}>{content}</ReactMarkdown>
            )}
        </>
    );
};

export default MarkdownWithLaTeX;