import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useWindowWidth from "./useWindowWidth";

const CourseCard = (props) => {
    let widthValue = "31%";
    const width = useWindowWidth(1200);
    const widthTwo = useWindowWidth(770);
    const navigate = useNavigate();
    const [backgroundColor, setBackgroundColor] = useState(props.color); // make an api call when a drag and drop occurs to update default color

    if (width) {
        if (widthTwo) {
            widthValue = "100%";
        } else {
            widthValue = "47%";
        }
    }

    const handleCourseClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/${props.courseCode}`, '_blank');
        } else {
            // Navigate in the same tab
            navigate(`/${props.courseCode}`);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    }

    const handleDrop = async (e) => {
        e.preventDefault();
        const color = e.dataTransfer.getData("color");
        setBackgroundColor(color);

        const url = process.env.REACT_APP_COURSE_API_URL + "/change-color"

        const urlOptions = {    
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                color: color,
                courseCode: props.courseCode,
            })
        }
        
        try {
            const res = await fetch(url, urlOptions);
            if (res.status === 401) {
                window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
            }
    
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <div className="course-card-container" onClick={handleCourseClick} style={{ width: `${widthValue}`, backgroundColor: backgroundColor }} onDragOver={ (e) => {handleDragOver(e)} }  onDrop={ (e) => {handleDrop(e)} }>
                <div className="course-card-info-container">
                    <div className="course-card-name">
                        { props.name }
                    </div>
                    <div className="course-card-description">
                        { props.description }
                    </div>
                </div>
            </div>
        </>
    );
}

export default CourseCard;