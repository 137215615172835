import React, { useState, useEffect } from "react";
import TrashQuestion from "./trashQuestion";
import Grid from "./grid";
import BubbleWithTextView from "./bubbleWithTextView";
import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";
import Explanation from "./explanation";
import Tippy from "@tippyjs/react";
import { useLocation, useNavigate } from "react-router-dom";

const TrueFalseQuestionEdit = ({ explanation, useExplanation, updateQuestions, questionsToSave, showDrag, updateQuestionsToSave, provided, updateDeleteQuestion, index, points, question, uuid, questionType, bubbled, bankUuid }) => {
    const [form, setForm] = useState({
        question: question,
        points: Number(points).toFixed(2),
        bubbled: bubbled,
        uuid: uuid,
        questionType: questionType,
        useExplanation: useExplanation,
        explanation: explanation
    });
    const navigate = useNavigate();
    const location = useLocation();

    function updateBubbled(newBubbled) {
        if (newBubbled === form.bubbled) {
            setForm((prev) => ({
                ...prev,
                bubbled: -1
            }))
        } else {
            setForm((prev) => ({
                ...prev,
                bubbled: newBubbled
            }))
        }
    }

    function updateForm(key, value) {
        setForm((prev) => (
            {
                ...prev,
                [key]: value
            }
        ))
    }

    const submitForm = async () => {
        // construct a new body for post with all values that have changed
        let body = {};
        body.fields = {}

        // check if we have something previous
        if (form.uuid in questionsToSave) {
            body.fields = questionsToSave[form.uuid].fields
        }

        body.uuid = uuid
        body.questionType = questionType

        // Check each property for changes
        Object.keys(form).forEach(key => {
            if (form[key] !== { question, points, bubbled, uuid, questionType, useExplanation, explanation }[key]) {
                if (key !== "uuid" || key !== "questionType") {
                    if (key === "points") {
                        if (form.points !== Number(points).toFixed(2)) {
                            body.fields[key] = form[key];
                        }
                    } else {
                        body.fields[key] = form[key];
                    }
                }
            }
        });

        if (Object.keys(body.fields).length === 0) {
            return;
        }

        // updateQuestionsToSave
        updateQuestionsToSave((prev) => ({
            ...prev,
            [form.uuid]: body
        }))
    };

    useEffect(() => {
        // Define a timeout to submit the form after 0.5s
        const timer = setTimeout(() => {
            submitForm();
        }, 500); // 500ms delay
    
        // Cleanup function to clear the timeout if dependencies change
        return () => {
            clearTimeout(timer);
        };
    }, [form.points, form.question, form.explanation]);

    useEffect(() => {
        submitForm();
    }, [form.bubbled, form.useExplanation])

    useEffect(() => {
        updateQuestions((prev) => 
            prev.map((question) => {
                if (question.questionType === TypesOfQuestionsEnum.BANK_OF_QUESTIONS) {
                    return {
                        ...question,
                        questions: question.questions.map((nestedQuestion) => {
                            if (nestedQuestion.uuid === form.uuid) {
                                return form;
                            }
                            return nestedQuestion;
                        }),
                    };
                } else if (question.uuid === form.uuid) {
                    return form;
                }
                return question;
            })
        );
    }, [form]);

    const setNewParams = () => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("uuid", uuid);
        navigate(`?${searchParams.toString()}`);
    }
    
    return (
        <>
            <div className="true-false-question-view" id={uuid} onClick={setNewParams}>
                <div className="question-number">
                    <div className="grid-number-container">
                        {
                            showDrag

                            ?

                            <>
                                <Grid provided={provided}/>
                            </>

                            :

                            <>
                            </>

                        }
                        Q{ index } 
                        <Tippy content={"Question data has ≈400KB max"} placement={"top"} theme="custom-tooltip">
                            <div className="docs-wrapper-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                                    <path stroke="currentColor" strokeWidth=".3" d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                </svg>
                            </div>
                        </Tippy>
                    </div>
                    <div className="pts">
                        <>
                            <input onBlur={submitForm} className="grading-box-question" type="none" value={form.points} onChange={(e) => {
                                updateForm("points", e.target.value);
                            }}/>
                            pts
                        </>
                        <TrashQuestion uuid={uuid} updateDeleteQuestion={updateDeleteQuestion} bankUuid={bankUuid}/>
                    </div>
                </div>
                <div className="text-response-area">
                    <textarea onBlur={submitForm} className="text-response-area-input" placeholder="Enter question (render Markdown and LaTeX)" style={{ minHeight: "100px" }} value={form.question} onChange={(e) => {
                        updateForm("question", e.target.value);
                    }}/>
                </div>
                <div className="true-false-answer-block">
                    <BubbleWithTextView text={"True"} bubbled={form.bubbled === 1} updateBubbled={updateBubbled}/>
                    <BubbleWithTextView text={"False"} bubbled={form.bubbled === 0} updateBubbled={updateBubbled}/>
                </div>
                <div className="select-all-that-apply-array">
                    <Explanation form={form} updateForm={updateForm} submitForm={submitForm}/>
                </div>
            </div>
        </>
    );
}

export default TrueFalseQuestionEdit;